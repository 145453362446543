import { FieldNames } from 'app/models/fields/names';
import { Currency } from 'app/components/FormFields/Currency';
import { Address } from 'app/components/FormFields/Address';
import { State } from 'app/components/FormFields/State';
import { SinglePastDate } from 'app/components/FormFields/SinglePastDate';
import { ZipCode } from 'app/components/FormFields/ZipCode';
import { Question } from 'app/models/types';
import { SHARED, INCOME_DISCLAIMER } from 'app/models/questions/shared';
import { YES_OR_NO_OPTIONS, LIVING_SITUATION_OPTIONS, EMPLOYMENT_TYPE_OPTIONS, COBORROWER_EMPLOYMENT_TYPE_OPTIONS } from 'app/models/options/options';
import { RadioField } from 'app/components/FormFields/RadioField';
import { hasCoBorrower, coBorrowerHasDifferentAddress, isLessThanTwoYrsAtJob, isCoLessThanTwoYrsAtJob } from 'app/models/fields/conditionals';
import { TextInput } from 'app/components/FormFields/TextInput';
import { RetirementIncomeSources } from 'app/components/FormFieldArrays/RetirementIncomeSources';
import { OtherIncomeSources } from 'app/components/FormFieldArrays/OtherIncomeSources';
import { SwitchToggle } from 'app/components/FormFields/SwitchToggle';
import { PersonalLoanCreditReportResult } from 'app/components/PersonalLoan/PersonalLoanCreditReportResult';
import {
  PERSONAL_LOAN_CREDIT_REPORT_RESULT_QUESTION,
  PERSONAL_LOAN_FINAL_QUESTION,
  PERSONAL_LOAN_LOAN_AMOUNT_QUESTION,
} from 'app/models/sections/constants';
import { CreditScoresRadio } from '../../components/FormFields/CreditScoresRadio';
import { PersonalLoanConfirmation } from 'app/components/PersonalLoan/PersonalLoanConfirmation';

/**
 * Takes in a field name and returns the corresponding personal loan
 * question
 *
 * @param {string} name Field name
 * @returns Question with matching field name
 */
export const personalLoanQuestionByName = (name: string): Question => {
  return PERSONAL_LOAN_QUESTIONS.find((question) => question.name === name) as Question;
};

/**
 * Takes in a question id and returns the corresponding personal loan
 * question
 *
 * @param {number} id Question id
 * @returns Question with matching id
 */
export const personalLoanQuestionById = (id: number): Question => {
  return PERSONAL_LOAN_QUESTIONS.find((question) => question.id === id) as Question;
};

/**
 * Takes in an array of field names and returns an array of questions
 *
 * @param {string[]} [names=[]] Field names
 * @returns Questions matching field names
 */
export const personalLoanQuestionsByName = (names: string[] = []): Question[] => {
  const questions: Question[] = [];
  for (const name of names) {
    questions.push(personalLoanQuestionByName(name));
  }
  return questions;
};

export const PERSONAL_LOAN_QUESTIONS: Question[] = [
  {
    id        : PERSONAL_LOAN_LOAN_AMOUNT_QUESTION,
    component : Currency,
    name      : FieldNames.loanAmount,
    label     : 'Loan Amount',
    hideTitle : true,
    validator : {
      type: 'isBetween',
      param: [FieldNames.minLoanAmount, FieldNames.maxLoanAmount],
    },
  },
  {
    id        : 1002,
    name      : FieldNames.creditScore,
    component : CreditScoresRadio,
    popover   : 'This is the estimate of your current credit score. After you authorize an official credit pull, the middle of 3 scores will be used for your mortgage application.',
    label     : 'Credit Score',
  },
  {
    id: 1003,
    ...SHARED[FieldNames.propertyZip],
    hideTitle : true,
  },
  {
    id: 1004,
    ...SHARED[FieldNames.firstName],
  },
  {
    id: 1005,
    ...SHARED[FieldNames.middleName],
  },
  {
    id: 1006,
    ...SHARED[FieldNames.lastName],
  },
  {
    id: 1007,
    ...SHARED[FieldNames.phone],
  },
  {
    id: 1008,
    ...SHARED[FieldNames.email],
  },
  {
    id: 1009,
    ...SHARED[FieldNames.dateOfBirth],
  },
  {
    id        : 1010,
    component : RadioField,
    name      : FieldNames.coBorrowerYN,
    options   : YES_OR_NO_OPTIONS,
    title     : 'Will you have a co-borrower?',
  },
  {
    ...SHARED[FieldNames.firstName],
    id     : 1011,
    name   : FieldNames.coBorrowerFirstName,
    title  : 'Tell us about the co-borrower',
    showIf : [hasCoBorrower],
  },
  {
    ...SHARED[FieldNames.lastName],
    id     : 1012,
    name   : FieldNames.coBorrowerLastName,
    showIf : [hasCoBorrower],
  },
  {
    ...SHARED[FieldNames.middleName],
    id     : 1013,
    name   : FieldNames.coBorrowerMiddleName,
    showIf : [hasCoBorrower],
  },
  {
    ...SHARED[FieldNames.email],
    id     : 1014,
    name   : FieldNames.coBorrowerEmail,
    showIf : [hasCoBorrower],
  },
  {
    ...SHARED[FieldNames.dateOfBirth],
    id     : 1015,
    name   : FieldNames.coBorrowerDOB,
    showIf : [hasCoBorrower],
  },
  {
    id        : 1016,
    component : RadioField,
    name      : FieldNames.livingRentOrOwn,
    options   : LIVING_SITUATION_OPTIONS,
  },
  {
    id        : 1018,
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.livingPropertyStreet,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPropertyStreet,
    },
  },
  {
    id         : 1019,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.livingPropertyStreet2,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPropertyStreet2,
    },
  },
  {
    id        : 1020,
    component : Address,
    label     : 'City',
    name      : FieldNames.livingPropertyCity,
  },
  {
    id        : 1021,
    component : State,
    label     : 'State',
    name      : FieldNames.livingPropertyState,
  },
  {
    ...SHARED[FieldNames.propertyZip],
    id   : 1022,
    name : FieldNames.livingPropertyZip,
  },
  {
    id        : 1023,
    component : SinglePastDate,
    label     : 'Occupancy Start Date',
    name      : FieldNames.livingOccupancyStart,
  },
  {
    id        : 1025,
    component : Currency,
    label     : 'Annual Taxes',
    name      : FieldNames.livingYearTaxes,
  },
  {
    id        : 1026,
    component : Currency,
    label     : 'Annual Insurance',
    name      : FieldNames.livingYearInsure,
  },
  {
    id         : 1027,
    component  : Currency,
    isOptional : true,
    label      : 'Annual Homeowners Association Fees',
    name       : FieldNames.livingYearAssociate,
  },
  {
    id        : 1028,
    component : Currency,
    label     : 'Monthly Rent Payment',
    name      : FieldNames.livingMonthlyRentAmount,
  },
  {
    id        : 1029,
    component : RadioField,
    name      : FieldNames.coBorrowerSameAddress,
    options   : YES_OR_NO_OPTIONS,
  },
  {
    id        : 1030,
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.coBorrowerLivingPropertyStreet,
    title     : `Co-borrower's current address?`,
    showIf    : [coBorrowerHasDifferentAddress],
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.coBorrowerLivingPropertyStreet,
    },
  },
  {
    id         : 1031,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.coBorrowerLivingPropertyStreet2,
    showIf     : [coBorrowerHasDifferentAddress],
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.coBorrowerLivingPropertyStreet2,
    },
  },
  {
    id        : 1032,
    component : Address,
    label     : 'City',
    name      : FieldNames.coBorrowerLivingPropertyCity,
    showIf    : [coBorrowerHasDifferentAddress],
  },
  {
    id        : 1033,
    component : State,
    label     : 'State',
    name      : FieldNames.coBorrowerLivingPropertyState,
    showIf    : [coBorrowerHasDifferentAddress],
  },
  {
    id        : 1034,
    component : ZipCode,
    label     : 'Zip Code',
    name      : FieldNames.coBorrowerLivingPropertyZip,
    showIf    : [coBorrowerHasDifferentAddress],
  },
  {
    id         : 1035,
    component  : RadioField,
    name       : FieldNames.employmentType,
    options    : EMPLOYMENT_TYPE_OPTIONS,
    disclaimer : INCOME_DISCLAIMER,
  },
  {
    id        : 1036,
    component : TextInput,
    label     : 'Company Name',
    name      : FieldNames.employCompanyName,
  },
  {
    id         : 1037,
    component  : TextInput,
    label      : 'Job Title',
    isOptional : true,
    name       : FieldNames.employTitle,
  },
  {
    id        : 1038,
    component : SinglePastDate,
    label     : 'Start Date',
    name      : FieldNames.employStart,
  },
  {
    id           : 1039,
    component    : Currency,
    label        : 'Monthly Gross Pay',
    name         : FieldNames.employBase,
    fieldPopover : true,
    popover      : 'Monthly gross pay can include base pay, OT, commission, bonuses and similar other pay.',
  },
  {
    id        : 1040,
    showIf    : [isLessThanTwoYrsAtJob],
    component : TextInput,
    label     : 'Previous Employers Name',
    name      : FieldNames.employPrevName,
    title     : 'Previous employment information',
    popover   : `Lenders will require previous employer information if you've been with this employer for less than 2 years.`,
  },
  {
    id        : 1041,
    showIf    : [isLessThanTwoYrsAtJob],
    component : SinglePastDate,
    label     : 'Start Date',
    name      : FieldNames.employPrevStart,
  },
  {
    id        : 1042,
    showIf    : [isLessThanTwoYrsAtJob],
    component : SinglePastDate,
    label     : 'End Date',
    name      : FieldNames.employPrevEnd,
    validator: {
      type : 'isAfterStartDate',
      param: FieldNames.employPrevStart,
    },
  },
  {
    id        : 1043,
    component : RetirementIncomeSources,
    title     : 'Tell us about your income',
    name      : FieldNames.retirementIncome,
  },
  {
    id        : 1044,
    component : OtherIncomeSources,
    title     : 'Tell us about your income',
    name      : FieldNames.otherIncome,
  },
  {
    id        : 1045,
    component : RadioField,
    name      : FieldNames.incomeOnDeclineYN,
    options   : YES_OR_NO_OPTIONS,
    title     : 'Is your income expected to decline?',
  },
  {
    id        : 1046,
    component : RadioField,
    name      : FieldNames.coBorrowerEmploymentType,
    options   : COBORROWER_EMPLOYMENT_TYPE_OPTIONS,
  },
  {
    id        : 1047,
    component : TextInput,
    label     : 'Company Name',
    name      : FieldNames.coBorrowerEmployCompanyName,
  },
  {
    id        : 1048,
    component : TextInput,
    label     : 'Job Title',
    isOptional: true,
    name      : FieldNames.coBorrowerEmployTitle,
  },
  {
    id        : 1049,
    component : SinglePastDate,
    label     : 'Start Date',
    name      : FieldNames.coBorrowerEmployStart,
  },
  {
    id           : 1050,
    component    : Currency,
    label        : 'Monthly Gross Pay',
    name         : FieldNames.coBorrowerEmployBase,
    fieldPopover : true,
    popover      : 'Monthly gross pay can include base pay, OT, commission, bonuses and similar other pay.',
  },
  {
    id        : 1051,
    showIf    : [isCoLessThanTwoYrsAtJob],
    component : TextInput,
    label     : 'Previous Employers Name',
    name      : FieldNames.coBorrowerEmployPrevName,
    title     : 'Previous employment information',
    popover   : `Lenders will require previous employer information if you've been with this employer for less than 2 years.`,
  },
  {
    id        : 1052,
    showIf    : [isCoLessThanTwoYrsAtJob],
    component : SinglePastDate,
    label     : 'Start Date',
    name      : FieldNames.coBorrowerEmployPrevStart,
  },
  {
    id        : 1053,
    showIf    : [isCoLessThanTwoYrsAtJob],
    component : SinglePastDate,
    label     : 'End Date',
    name      : FieldNames.coBorrowerEmployPrevEnd,
    validator: {
      type : 'isAfterStartDate',
      param: FieldNames.coBorrowerEmployPrevStart,
    },
  },
  {
    id        : 1054,
    title     : 'Tell us about your income',
    component : RetirementIncomeSources,
    name      : FieldNames.coBorrowerRetirementIncome,
  },
  {
    id        : 1055,
    title     : 'Tell us about your income',
    component : OtherIncomeSources,
    name      : FieldNames.coBorrowerOtherIncome,
  },
  {
    id        : 1056,
    component : RadioField,
    name      : FieldNames.coBorrowerIncomeOnDeclineYN,
    options   : YES_OR_NO_OPTIONS,
    title     : 'Is your income expected to decline?',
  },
  {
    id        : 1057,
    component    : (props) => <SwitchToggle {...props} isThinStyle={true} /> ,
    name      : `${FieldNames.decBorrower}.${FieldNames.hasCitizenship}`,
    label     : 'Are you a US Citizen or permanent resident?',
  },
  {
    id        : 1058,
    showIf    : [hasCoBorrower],
    component    : (props) => <SwitchToggle {...props} isThinStyle={true} /> ,
    name      : `${FieldNames.decCoBorrower}.${FieldNames.hasCitizenship}`,
    label     : 'Is your co-borrower a US Citizen or permanent resident?',
  },
  {
    id        : PERSONAL_LOAN_CREDIT_REPORT_RESULT_QUESTION,
    component : PersonalLoanCreditReportResult,
    name      : FieldNames.creditScore,
  },
  {
    id: 1060,
    ...SHARED[FieldNames.suffixName],
  },
  {
    id: 1061,
    ...SHARED[FieldNames.coBorrowerSuffixName],
  },
  {
    id        : 1062,
    component : RadioField,
    name      : FieldNames.hasCurrentCheckingAccount,
    options   : YES_OR_NO_OPTIONS,
    title     : 'not real but is essential and uses translations',
  },
  {
    id        : 1063,
    component : RadioField,
    name      : FieldNames.hasUniquePersonalLoanProgram,
    options   : YES_OR_NO_OPTIONS,
    title     : 'This is a unique client-specific question',
  },
  {
    id          : PERSONAL_LOAN_FINAL_QUESTION,
    component   : PersonalLoanConfirmation,
    name        : 'FinalPage',
  },
];
