import { RadioField } from 'app/components/FormFields/RadioField';
import {
  COMMERCIAL_BUSINESS_TYPE_OPTIONS,
  COMMERCIAL_ENTITY_TYPE_OPTIONS,
  COMMERCIAL_LIVING_SITUATION_OPTIONS,
  COMMERCIAL_LOAN_HOLDER_OPTIONS,
  COMMERCIAL_NUMBER_OF_STRUCTURES_OPTIONS,
  COMMERCIAL_PROPERTY_TYPE_OPTIONS,
  COMMERCIAL_TREND_OPTIONS,
  EMPLOYMENT_TYPE_OPTIONS,
  YEAR_1970PLUS_TO_NOW,
  REFINANCE_REASON_OPTIONS,
  STATE_OPTIONS,
  YES_OR_NO_OPTIONS, COMMERCIAL_BUSINESS_OWNERSHIP_TYPE_OPTIONS,
} from 'app/models/options/options';
import { Question } from 'app/models/types';
import { FieldNames } from 'app/models/fields/names';
import { INCOME_DISCLAIMER, SHARED } from 'app/models/questions/shared';
import { Address } from 'app/components/FormFields/Address';
import { State } from 'app/components/FormFields/State';
import { ZipCode } from 'app/components/FormFields/ZipCode';
import { TextInput } from 'app/components/FormFields/TextInput';
import { Currency } from 'app/components/FormFields/Currency';
import { Select } from 'app/components/FormFields/Select';
import {
  isRetired,
  isEmploymentOther,
  isLessThanTwoYrsAtJob,
  isEmploySameBusiness,
  isSameBusiness,
  hasOtherManagement,
} from 'app/models/fields/conditionals';
import { SinglePastDate } from 'app/components/FormFields/SinglePastDate';
import { Name } from 'app/components/FormFields/Name';
import { IncomeSources } from 'app/components/FormFieldArrays/IncomeSources';
import { RetirementIncomeSources } from 'app/components/FormFieldArrays/RetirementIncomeSources';
import { OtherIncomeSources } from 'app/components/FormFieldArrays/OtherIncomeSources';
import { Phone } from '../../components/FormFields/Phone';
import { TextArea } from '../../components/FormFields/TextArea';
import { Owners } from '../../components/FormFieldArrays/Owners';
import { BusinessManagement } from '../../components/FormFieldArrays/BusinessManagement';
import { IntegerAllowZero } from '../../components/FormFields/IntegerAllowZero';
import { Integer } from '../../components/FormFields/Integer';

/**
 * Takes in a field name and returns the corresponding refinance question
 *
 * @param {string} name Field name
 * @returns {Question} Question with matching field name
 */
export const commercialRefinanceQuestionByName = (name: string): Question => {
  return COMMERCIAL_REFINANCE_QUESTIONS.find((question) => {
    return question.name === name;
  }) as Question;
};

/**
 * Takes in an array of field names and returns an array of questions
 *
 * @param {string[]} [names=[]] Field names
 * @returns Questions matching field names
 */
export const commercialRefinanceQuestionsByName = (names: string[] = []): Question[] => {
  const questions: Question[] = [];
  for (const name of names) {
    questions.push(commercialRefinanceQuestionByName(name));
  }
  return questions;
};

export const COMMERCIAL_REFINANCE_QUESTIONS: Question[] = [
  {
    id        : 1001,
    component : RadioField,
    label     : 'Purpose of Refinance',
    hideLabel : true,
    name      : FieldNames.loanPurpose,
    options   : REFINANCE_REASON_OPTIONS,
    title     : 'What is the purpose of your refinance?',
  },
  {
    id: 1002,
    ...SHARED[FieldNames.firstName],
  },
  {
    id: 1003,
    ...SHARED[FieldNames.middleName],
  },
  {
    id: 1004,
    ...SHARED[FieldNames.lastName],
  },
  {
    id: 1005,
    ...SHARED[FieldNames.email],
  },
  {
    id: 1006,
    ...SHARED[FieldNames.phone],
  },
  {
    id: 1007,
    ...SHARED[FieldNames.dateOfBirth],
  },
  {
    id        : 1008,
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.propertyStreet,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.propertyStreet,
    },
  },
  {
    id         : 1009,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.propertyStreet2,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.propertyStreet2,
    },
  },
  {
    id        : 1010,
    component : Address,
    label     : 'City',
    name      : FieldNames.propertyCity,
  },
  {
    id        : 1011,
    component : State,
    label     : 'State',
    name      : FieldNames.propertyState,
  },
  {
    id        : 1012,
    component : ZipCode,
    label     : 'Zip Code',
    name      : FieldNames.propertyZip,
  },
  {
    id        : 1013,
    component : Currency,
    label     : 'Requested Loan Amount', // translation is used here
    name      : FieldNames.loanAmount,
  },
  {
    id        : 1014,
    component : Currency,
    label     : 'Annual Taxes',
    name      : FieldNames.yearTaxes,
  },
  {
    id        : 1015,
    component : Currency,
    label     : 'Annual Insurance',
    name      : FieldNames.yearInsure,
  },
  {
    ...SHARED[FieldNames.propertyType],
    id           : 1016,
    component    : Select,
    fieldPopover : true,
    name         : FieldNames.livingPropertyType,
  },
  {
    id        : 1017,
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.livingPropertyStreet,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPropertyStreet,
    },
  },
  {
    id         : 1018,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.livingPropertyStreet2,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPropertyStreet2,
    },
  },
  {
    id        : 1019,
    component : Address,
    label     : 'City',
    name      : FieldNames.livingPropertyCity,
  },
  {
    id        : 1020,
    component : State,
    label     : 'State',
    name      : FieldNames.livingPropertyState,
  },
  {
    ...SHARED[FieldNames.propertyZip],
    id     : 1021,
    name   : FieldNames.livingPropertyZip,
  },
  {
    id         : 1022,
    component  : RadioField,
    name       : FieldNames.employmentType,
    options    : EMPLOYMENT_TYPE_OPTIONS,
    disclaimer : INCOME_DISCLAIMER,
  },
  {
    id        : 1023,
    component : TextInput,
    label     : 'Company Name',
    name      : FieldNames.employCompanyName,
    showIf    : [(values) => !isEmploySameBusiness(values)],
  },
  {
    id        : 1024,
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.employStreet,
    showIf    : [(values) => !isEmploySameBusiness(values)],
  },
  {
    id         : 1025,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.employStreet2,
    showIf    : [(values) => !isEmploySameBusiness(values)],
  },
  {
    id        : 1026,
    component : Address,
    label     : 'City',
    name      : FieldNames.employCity,
    showIf    : [(values) => !isEmploySameBusiness(values)],
  },
  {
    id        : 1027,
    component : State,
    label     : 'State',
    name      : FieldNames.employState,
    showIf    : [(values) => !isEmploySameBusiness(values)],
  },
  {
    id        : 1028,
    component : ZipCode,
    label     : 'Zip Code',
    name      : FieldNames.employZip,
    showIf    : [(values) => !isEmploySameBusiness(values)],
  },
  {
    id         : 1029,
    component  : TextInput,
    label      : 'Job Title',
    isOptional : true,
    name       : FieldNames.employTitle,
  },
  {
    ...SHARED[FieldNames.phone],
    id   : 1030,
    name : FieldNames.employPhone,
  },
  {
    id        : 1031,
    component : SinglePastDate,
    label     : 'Start Date',
    name      : FieldNames.employStart,
  },
  {
    id        : 1032,
    component : Currency,
    label     : 'Monthly Base Pay',
    name      : FieldNames.employBase,
  },
  {
    id           : 1033,
    component    : Currency,
    fieldPopover : true,
    isOptional   : true,
    label        : 'Monthly Overtime',
    name         : FieldNames.employOvertime,
    popover      : 'Overtime can be included in your income if it has been consistent over a two-year period.',
  },
  {
    id           : 1034,
    component    : Currency,
    fieldPopover : true,
    isOptional   : true,
    label        : 'Monthly Commission',
    name         : FieldNames.employCommission,
    popover      : 'Commissions can be included in your income if they have been consistent over a two-year period.',
  },
  {
    id           : 1035,
    component    : Currency,
    fieldPopover : true,
    isOptional   : true,
    label        : 'Monthly Bonus',
    name         : FieldNames.employBonus,
    popover      : 'Bonuses can be included in your income if they have been consistent over a two-year period.',
  },
  {
    id        : 1036,
    component : IncomeSources,
    title     : 'Additional income sources?',
    name      : FieldNames.additionalIncome,
  },
  {
    id        : 1037,
    component : TextInput,
    label     : 'Previous Employers Name',
    name      : FieldNames.employPrevName,
    title     : 'Previous employment information',
    showIf    : [isLessThanTwoYrsAtJob],
    popover   : 'Lenders will require previous employer information if you\'ve been with this employer for less than 2 years.',
  },
  {
    id        : 1038,
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.employPrevStreet,
    showIf    : [isLessThanTwoYrsAtJob],
  },
  {
    id         : 1039,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.employPrevStreet2,
    showIf     : [isLessThanTwoYrsAtJob],
  },
  {
    id        : 1040,
    component : Address,
    label     : 'City',
    name      : FieldNames.employPrevCity,
    showIf    : [isLessThanTwoYrsAtJob],
  },
  {
    id        : 1041,
    component : State,
    label     : 'State',
    name      : FieldNames.employPrevState,
    showIf    : [isLessThanTwoYrsAtJob],
  },
  {
    id        : 1042,
    component : ZipCode,
    label     : 'Zip',
    name      : FieldNames.employPrevZip,
    showIf    : [isLessThanTwoYrsAtJob],
  },
  {
    id        : 1043,
    component : SinglePastDate,
    label     : 'Start Date',
    name      : FieldNames.employPrevStart,
    showIf    : [isLessThanTwoYrsAtJob],
  },
  {
    id        : 1044,
    component : SinglePastDate,
    label     : 'End Date',
    name      : FieldNames.employPrevEnd,
    showIf    : [isLessThanTwoYrsAtJob],
    validator: {
      type : 'isAfterStartDate',
      param: FieldNames.employPrevStart,
    },
  },
  {
    id        : 1045,
    component : RetirementIncomeSources,
    title     : 'Tell us about your retirement income',
    name      : FieldNames.retirementIncome,
    showIf    : [isRetired],
  },
  {
    id        : 1046,
    component : OtherIncomeSources,
    title     : 'Tell us about your other income',
    name      : FieldNames.otherIncome,
    showIf    : [isEmploymentOther],
  },
  {
    id        : 1047,
    component : Currency,
    label     : 'Monthly Rent Payment',
    name      : FieldNames.livingMonthlyRentAmount,
  },
  {
    id: 1048,
    ...SHARED[FieldNames.suffixName],
  },
  {
    id: 1049,
    ...SHARED[FieldNames.employPrevTitle],
  },
  {
    id           : 1050,
    component    : Select,
    label        : 'Property Type',
    name         : FieldNames.commercialPropertyType,
    fieldPopover : true,
    options      : COMMERCIAL_PROPERTY_TYPE_OPTIONS,
  },
  {
    id        : 1051,
    component : Currency,
    label     : 'Current Total Lien Balance',
    name      : FieldNames.commercialLienBalance,
  },
  {
    id         : 1052,
    component  : Name,
    label      : 'Business Name',
    name       : FieldNames.commercialBusinessName,
  },
  {
    id        : 1053,
    component : RadioField,
    title     : `Will the loan be held by the business or an individual?`,
    name      : FieldNames.commercialLoanHolder,
    options   : COMMERCIAL_LOAN_HOLDER_OPTIONS,
  },
  {
    id        : 1054,
    component : RadioField,
    title     : `Is the business address the same as the property to be refinanced?`,
    name      : FieldNames.commercialSameBusiness,
    options   : YES_OR_NO_OPTIONS,
  },
  {
    id        : 1055,
    component : Currency,
    label     : 'Estimated Property Value',
    name      : FieldNames.propertyValue,
  },
  {
    id        : 1056,
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.commercialStreet,
    showIf    : [(values) => !isSameBusiness(values)],
  },
  {
    id         : 1057,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.commercialStreet2,
    showIf     : [(values) => !isSameBusiness(values)],
  },
  {
    id        : 1058,
    component : Address,
    label     : 'City',
    name      : FieldNames.commercialCity,
    showIf    : [(values) => !isSameBusiness(values)],
  },
  {
    id        : 1059,
    component : State,
    label     : 'State',
    name      : FieldNames.commercialState,
    showIf    : [(values) => !isSameBusiness(values)],
  },
  {
    id        : 1060,
    component : ZipCode,
    label     : 'Zip Code',
    name      : FieldNames.commercialZip,
    showIf    : [(values) => !isSameBusiness(values)],
  },
  {
    ...SHARED[FieldNames.phone],
    id     : 1061,
    name   : FieldNames.commercialPhone,
  },
  {
    id         : 1062,
    component  : TextInput,
    isOptional : true,
    label      : `DBA`,
    name       : FieldNames.commercialDBA,
  },
  {
    id         : 1063,
    component  : TextInput,
    isOptional : true,
    label      : `Business Website`,
    name       : FieldNames.commercialWebsite,
  },
  {
    id         : 1064,
    component  : Select,
    label      : `Entity Type`,
    name       : FieldNames.commercialEntityType,
    options   : COMMERCIAL_ENTITY_TYPE_OPTIONS,
  },
  {
    id         : 1065,
    component  : Select,
    label      : `Business Type`,
    name       : FieldNames.commercialBusinessType,
    options   : COMMERCIAL_BUSINESS_TYPE_OPTIONS,
  },
  {
    id         : 1066,
    component  : Select,
    label      : `3 Year Revenue Trend`,
    name       : FieldNames.commercialRevenueTrend3,
    options    : COMMERCIAL_TREND_OPTIONS,
  },
  {
    id         : 1067,
    component  : Select,
    label      : `3 Year Profitability Trend`,
    name       : FieldNames.commercialProfitTrend3,
    options    : COMMERCIAL_TREND_OPTIONS,
  },
  {
    id        : 1068,
    component : Integer,
    label     : `Number of Employees`,
    name      : FieldNames.commercialEmployeeCount,
  },
  {
    id        : 1069,
    component : RadioField,
    title     : `Do you work for the business seeking the loan?`,
    name      : FieldNames.commercialEmploySameBusiness,
    options   : YES_OR_NO_OPTIONS,
  },
  {
    id         : 1070,
    component  : IntegerAllowZero,
    label      : 'Land or Site Size (approximately)',
    endAdornment : 'Square feet',
    name       : FieldNames.landSize,
    validator  : {
      type  : 'isGreaterThanZero',
      param : FieldNames.landSize,
    },
  },
  {
    id         : 1071,
    component  : Select,
    label      : 'Number of Buildings or Structures',
    name       : FieldNames.numOfStructures,
    options    : COMMERCIAL_NUMBER_OF_STRUCTURES_OPTIONS,
  },
  {
    id         : 1072,
    component  : IntegerAllowZero,
    label      : 'Building Square Footage (approximately)',
    name       : FieldNames.buildingSquareFootage,
  },
  {
    id         : 1073,
    component  : Select,
    isOptional : true,
    label      : 'Year Built',
    name       : FieldNames.builtYear,
    options    : YEAR_1970PLUS_TO_NOW,
  },
  {
    id         : 1074,
    component  : TextInput,
    label      : 'Expected Owner Occupancy %',
    name       : FieldNames.expectedOwnerOccupancy,
    validator: {
      type : 'isPercentage',
      param: FieldNames.expectedOwnerOccupancy,
    },
  },
  {
    id: 1075,
    label       : 'Current Zoning Classification',
    component   : TextInput,
    name        : FieldNames.currentZoningClassification,
    isOptional  : true,
  },
  {
    id: 1076,
    label       : 'Building Contact Name',
    component   : Name,
    name        : FieldNames.buildingContactName,
    isOptional  : true,
  },
  {
    id: 1077,
    label       : 'Building Contact Phone',
    component   : Phone,
    name        : FieldNames.buildingContactPhone,
    isOptional  : true,
  },
  {
    id: 1078,
    label       : 'Revenue Explanation',
    component   : TextArea,
    name        : FieldNames.commercialRevenueExplanation,
  },
  {
    id: 1079,
    label       : 'Profit Explanation',
    component   : TextArea,
    name        : FieldNames.commercialProfitExplanation,
  },
  {
    id: 1080,
    label       : 'List of Key Collateral',
    component   : TextArea,
    name        : FieldNames.commercialCollateralList,
  },
  {
    id: 1081,
    component   : Owners,
    name        : FieldNames.commercialOwnerList,
  },
  {
    id: 1082,
    component   : BusinessManagement,
    name        : FieldNames.commercialManagementList,
    showIf      : [hasOtherManagement],
  },
  {
    id: 1083,
    component   : Select,
    label       : 'State of Incorporation',
    name        : FieldNames.commercialStateOfIncorporation,
    options     : STATE_OPTIONS,
  },
  {
    id: 1084,
    component   : Select,
    label       : 'Year Started',
    name        : FieldNames.commercialYearStarted,
    options     : YEAR_1970PLUS_TO_NOW,
  },
  {
    id        : 1085,
    component : RadioField,
    name      : FieldNames.livingRentOrOwn,
    options   : COMMERCIAL_LIVING_SITUATION_OPTIONS,
  },
  {
    id        : 1086,
    component : RadioField,
    title     : 'Are there other key business management personnel?',
    name      : FieldNames.hasOtherManagement,
    options   : YES_OR_NO_OPTIONS,
  },

  {
    id         : 1087,
    component  : Integer,
    isOptional : true,
    label      : 'Census Tract',
    name       : FieldNames.commercialCensusTract,
  },
  {
    id         : 1088,
    component  : Integer,
    isOptional : true,
    label      : 'NAICS Code',
    name       : FieldNames.commercialNAICSCode,
  },
  {
    id        : 1089,
    component : Currency,
    label     : 'Revenue for Last Fiscal Year',
    name      : FieldNames.commercialRevenueLastYear,
  },
  {
    id         : 1090,
    component  : Select,
    label      : 'Tell us about business ownership',
    name       : FieldNames.commercialBusinessOwnershipType,
    options    : COMMERCIAL_BUSINESS_OWNERSHIP_TYPE_OPTIONS,
  },
  {
    id        : 1091,
    component : TextInput,
    label     : 'Loan Number',
    name      : FieldNames.existingLoanNumber,
  },
  {
    id       : 1092,
    component: RadioField,
    name     : FieldNames.isCommercialExistingCustomer,
    options  : YES_OR_NO_OPTIONS,
    title    : 'Are you an existing Commercial Loan customer?',
  },
];
