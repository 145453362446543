import { Address } from 'app/components/FormFields/Address';
import { CreditScoresRadio } from 'app/components/FormFields/CreditScoresRadio';
import { Currency } from 'app/components/FormFields/Currency';
import { CurrencyAllowZero } from 'app/components/FormFields/CurrencyAllowZero';
import { Name } from 'app/components/FormFields/Name';
import { RadioField } from 'app/components/FormFields/RadioField';
import { Select } from 'app/components/FormFields/Select';
import { State } from 'app/components/FormFields/State';
import { TextInput } from 'app/components/FormFields/TextInput';
import { ZipCode } from 'app/components/FormFields/ZipCode';
import { ReoLiabilities } from 'app/components/Turbo/ReoLiabilities';
import { LoanOfficerLanding } from 'app/components/LoanOfficerLanding';
import { FinicityConnect } from 'app/components/Turbo/FinicityConnect';
import {
  coBorrowerHasDifferentAddress,
  hasAddlProperties,
  hasCoBorrower,
  isCoBorrowerEmploymentOther,
  isCoBorrowerRetired,
  isCoEmployee,
  isCoLessThanTwoYrsAtJob,
  isCoSelfEmployed,
  isCollectOtherHomeLiabilties,
  isEmployee,
  isEmploymentOther,
  isHomeEquityLoanPurposeOther,
  isLessThanTwoYrsAtJob,
  isOwningOtherHomes,
  isPrimaryHome,
  isRetired,
  isSelfEmployed,
} from 'app/models/fields/conditionals';
import { FieldNames } from 'app/models/fields/names';
import {
  COBORROWER_EMPLOYMENT_TYPE_OPTIONS,
  EMPLOYMENT_TYPE_OPTIONS,
  HOME_EQUITY_LOAN_PURPOSE_OPTIONS,
  HOME_EQUITY_REO_LIABILITY_USAGE,
  LIVING_SITUATION_OPTIONS,
  PROPERTY_TYPE_OPTIONS,
  PROPERTY_USAGE_OPTIONS,
  YES_OR_NO_OPTIONS,
} from 'app/models/options/options';
import { SHARED } from 'app/models/questions/shared';
import { Question } from 'app/models/types';
import { AdjustHomeValue } from '../../components/Turbo/AdjustHomeValue';
import { SinglePastDate } from 'app/components/FormFields/SinglePastDate';
import { IncomeSources } from 'app/components/FormFieldArrays/IncomeSources';
import { RetirementIncomeSources } from 'app/components/FormFieldArrays/RetirementIncomeSources';
import { OtherIncomeSources } from 'app/components/FormFieldArrays/OtherIncomeSources';
import { HOME_EQUITY_TURBO_COLLECT_SSN_QUESTION, HOME_EQUITY_TURBO_COLLECT_SSN_QUESTION_FINAL } from 'app/models/sections/constants';
import { CollectRequiredSsn } from 'app/components/FormFields/Ssn/CollectRequiredSsn';
import { CollectConditionallyRequiredSsn } from 'app/components/FormFields/Ssn/CollectConditionallyRequiredSsn';
import { AdditionalProperties } from 'app/components/FormFieldArrays/AdditionalProperties';

/**
 * Takes in a field name and returns the corresponding auto loan
 * question
 *
 * @param {string} name Field name
 * @returns {Question} Question with matching field name
 */
export const homeEquityTurboQuestionByName = (name: string): Question => {
  return HOME_EQUITY_TURBO_QUESTIONS.find((question) => {
    return question.name === name;
  }) as Question;
};

/**
 * Takes in an array of field names and returns an array of questions
 *
 * @param {string[]} [names=[]] Field names
 * @returns {Question[]} Questions matching field names
 */
export const homeEquityTurboQuestionsByName = (names: string[] = []): Question[] => {
  const questions: Question[] = [];
  for (const name of names) {
    questions.push(homeEquityTurboQuestionByName(name));
  }
  return questions;
};

export const homeEquityTurboQuestionById = (id: number): Question => {
  return HOME_EQUITY_TURBO_QUESTIONS.find((question) => question.id === id) as Question;
};

export const HOME_EQUITY_TURBO_QUESTIONS: Question[] = [
  {
    id        : 1001,
    component : Currency,
    label     : 'Estimated Home Value',
    name      : FieldNames.homeValue,
  },
  {
    id        : 1002,
    component : CurrencyAllowZero,
    label     : 'Current Mortgage Balance',
    name      : FieldNames.mortgageBalance,
  },
  {
    id: 1003,
    ...SHARED[FieldNames.propertyType],
    component    : Select,
    options      : PROPERTY_TYPE_OPTIONS,
    fieldPopover : true,
  },
  {
    ...SHARED[FieldNames.propertyUsage],
    id           : 1004,
    component    : Select,
    options      : PROPERTY_USAGE_OPTIONS,
    fieldPopover : true,
  },
  {
    id        : 1005,
    component : TextInput,
    name      : FieldNames.propertyStreet,
    label     : 'Street Address',
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.propertyStreet,
    },
  },
  {
    id         : 1006,
    component  : TextInput,
    name       : FieldNames.propertyStreet2,
    label      : 'Street Address 2',
    isOptional : true,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.propertyStreet2,
    },
  },
  {
    id        : 1007,
    component : TextInput,
    name      : FieldNames.propertyCity,
    label     : 'City',
  },
  {
    id        : 1008,
    component : State,
    name      : FieldNames.propertyState,
    label     : 'State',
  },
  {
    id        : 1009,
    component : ZipCode,
    name      : FieldNames.propertyZip,
    label     : 'Zip',
  },
  {
    ...SHARED[FieldNames.creditScore],
    id           : 1010,
    component    : CreditScoresRadio,
    title        : 'What is your estimated credit score?',
    fieldPopover : false,
    popover      : null,
  },
  {
    id        : 1011,
    component : Currency,
    label     : 'Loan Amount',
    title     : 'How much would you like to borrow?',
    name      : FieldNames.loanAmount,
    popover   : 'You can\'t borrow more than the maximum loan amount shown on the previous page.',
    validator : {
      type  : 'loanAmountIsValid',
      param : FieldNames.maxLoanAmount,
    },
  },
  {
    id: 1012,
    ...SHARED[FieldNames.firstName],
  },
  {
    id: 1013,
    ...SHARED[FieldNames.middleName],
  },
  {
    id: 1014,
    ...SHARED[FieldNames.lastName],
  },
  {
    id: 1015,
    ...SHARED[FieldNames.email],
  },
  {
    id: 1016,
    ...SHARED[FieldNames.phone],
  },
  {
    id: 1017,
    ...SHARED[FieldNames.suffixName],
  },
  {
    id: 1018,
    ...SHARED[FieldNames.dateOfBirth],
  },
  {
    id       : 1019,
    component: RadioField,
    name     : FieldNames.homeEquityLoanPurpose,
    options  : HOME_EQUITY_LOAN_PURPOSE_OPTIONS,
    title    : 'What is the purpose of your home equity loan?',
  },
  {
    id       : 1020,
    component: TextInput,
    name     : FieldNames.homeEquityLoanPurposeOther,
    label    : 'Reason',
    showIf   : [isHomeEquityLoanPurposeOther],
  },
  {
    id: 1021,
    ...SHARED[FieldNames.citizenshipType],
  },
  {
    id: 1022,
    ...SHARED[FieldNames.maritalStatus],
  },
  {
    id        : 1023,
    component : Currency,
    label     : 'Annual Taxes',
    name      : FieldNames.livingYearTaxes,
  },
  {
    id        : 1024,
    component : Currency,
    label     : 'Annual Insurance',
    name      : FieldNames.livingYearInsure,
  },
  {
    id         : 1025,
    component  : Currency,
    isOptional : true,
    label      : 'Annual Association Fees',
    name       : FieldNames.livingYearAssociate,
  },
  {
    id        : 1026,
    component : RadioField,
    name      : FieldNames.coBorrowerYN,
    options   : YES_OR_NO_OPTIONS,
    title     : 'Will you have a co-borrower?',
  },
  {
    ...SHARED[FieldNames.firstName],
    id     : 1027,
    title  : 'Tell us about the co-borrower',
    label  : 'Co-Borrower\'s First Name',
    name   : FieldNames.coBorrowerFirstName,
    showIf : [hasCoBorrower],
  },
  {
    id         : 1028,
    component  : Name,
    label      : 'Co-Borrower\'s Middle Name',
    name       : FieldNames.coBorrowerMiddleName,
    isOptional : true,
    showIf     : [hasCoBorrower],
  },
  {
    ...SHARED[FieldNames.lastName],
    id     : 1029,
    label  : 'Co-Borrower\'s Last Name',
    name   : FieldNames.coBorrowerLastName,
    showIf : [hasCoBorrower],
  },
  {
    ...SHARED[FieldNames.coBorrowerSuffixName],
    id     : 1030,
    showIf : [hasCoBorrower],
  },
  {
    ...SHARED[FieldNames.dateOfBirth],
    id     : 1031,
    label  : 'Co-Borrower\'s Date of Birth',
    name   : FieldNames.coBorrowerDOB,
    showIf : [hasCoBorrower],
  },
  {
    ...SHARED[FieldNames.email],
    id     : 1032,
    name   : FieldNames.coBorrowerEmail,
    showIf : [hasCoBorrower],
    validator : {
      type  : 'isNotEqual',
      param : FieldNames.email,
    },
  },
  {
    ...SHARED[FieldNames.phone],
    id     : 1033,
    name   : FieldNames.coBorrowerPhone,
    showIf : [hasCoBorrower],
  },
  {
    ...SHARED[FieldNames.coBorrowerCitizenshipType],
    id     : 1034,
    showIf : [hasCoBorrower],
  },
  {
    id        : 1035,
    component : RadioField,
    name      : FieldNames.coBorrowerSameAddress,
    options   : YES_OR_NO_OPTIONS,
    title     : 'Same address as borrower?',
    showIf    : [hasCoBorrower],
  },
  {
    id        : 1036,
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.coBorrowerLivingPropertyStreet,
    title     : `Co-borrower's current address?`,
    showIf    : [coBorrowerHasDifferentAddress],
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.coBorrowerLivingPropertyStreet,
    },
  },
  {
    id         : 1037,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.coBorrowerLivingPropertyStreet2,
    showIf     : [coBorrowerHasDifferentAddress],
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.coBorrowerLivingPropertyStreet2,
    },
  },
  {
    id        : 1038,
    component : Address,
    label     : 'City',
    name      : FieldNames.coBorrowerLivingPropertyCity,
    showIf    : [coBorrowerHasDifferentAddress],
  },
  {
    id        : 1039,
    component : State,
    label     : 'State',
    name      : FieldNames.coBorrowerLivingPropertyState,
    showIf    : [coBorrowerHasDifferentAddress],
  },
  {
    id        : 1040,
    component : ZipCode,
    label     : 'Zip Code',
    name      : FieldNames.coBorrowerLivingPropertyZip,
    showIf    : [coBorrowerHasDifferentAddress],
  },
  {
    id        : 1041,
    component : RadioField,
    name      : FieldNames.coBorrowerLivingRentOrOwn,
    options   : LIVING_SITUATION_OPTIONS,
    title     : `Co-borrower's current housing situation?`,
    showIf    : [coBorrowerHasDifferentAddress],
  },
  {
    ...SHARED[FieldNames.maritalStatus],
    id     : 1042,
    name   : FieldNames.coBorrowerMaritalStatus,
    title  : `Co-borrower's marital status?`,
    showIf : [hasCoBorrower],
  },
  {
    id        : 1043,
    component : LoanOfficerLanding,
    name      : FieldNames.loanOfficer,
  },
  {
    id        : 1044,
    component : ReoLiabilities,
    name      : FieldNames.realEstateLiabilities,
    options   : HOME_EQUITY_REO_LIABILITY_USAGE,
  },
  {
    id        : 1045,
    component : RadioField,
    name      : FieldNames.isOwningOtherHomes,
    title     : 'Do you own other homes in addition to your primary home and the collateral home for the home equity loan?',
    options   : YES_OR_NO_OPTIONS,
  },
  {
    id         : 1046,
    component  : Currency,
    name       : FieldNames.liabilityCombinedYearTaxes,
    title      : 'Please provide the values for taxes and insurance for all of your other homes combined',
    label      : 'Combined Annual Taxes',
    showIf     : [(values) => isOwningOtherHomes(values) || isCollectOtherHomeLiabilties(values)],
  },
  {
    id        : 1047,
    component : Currency,
    name      : FieldNames.liabilityCombinedYearInsure,
    label     : 'Combined Annual Insurance',
    showIf     : [(values) => isOwningOtherHomes(values) || isCollectOtherHomeLiabilties(values)],
  },
  {
    id         : 1048,
    component  : Currency,
    name       : FieldNames.liabilityCombinedYearAssociate,
    isOptional : true,
    label      : 'Combined Homeowners Association Fees',
    showIf     : [(values) => isOwningOtherHomes(values) || isCollectOtherHomeLiabilties(values)],
  },
  {
    id        : 1049,
    component : Currency,
    label     : 'Annual Taxes',
    name      : FieldNames.yearTaxes,
  },
  {
    id        : 1050,
    component : Currency,
    label     : 'Annual Insurance',
    name      : FieldNames.yearInsure,
  },
  {
    id         : 1051,
    component  : Currency,
    isOptional : true,
    label      : 'Annual Homeowners Association Fees',
    name       : FieldNames.yearAssociate,
  },
  {
    id        : 1052,
    name      : FieldNames.canVerifyOfIncomeEmployment,
    component : RadioField,
    options   : YES_OR_NO_OPTIONS,
  },
  {
    id        : 1053,
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.livingPropertyStreet,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPropertyStreet,
    },
  },
  {
    id         : 1054,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.livingPropertyStreet2,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPropertyStreet2,
    },
  },
  {
    id        : 1055,
    component : Address,
    label     : 'City',
    name      : FieldNames.livingPropertyCity,
  },
  {
    id        : 1056,
    component : State,
    label     : 'State',
    name      : FieldNames.livingPropertyState,
  },
  {
    id        : 1057,
    component : ZipCode,
    label     : 'Zip Code',
    name      : FieldNames.livingPropertyZip,
  },
  {
    ...SHARED[FieldNames.propertyType],
    id           : 1058,
    component    : Select,
    fieldPopover : true,
    name         : FieldNames.livingPropertyType,
  },
  {
    id        : 1059,
    component : Select,
    name      : FieldNames.liabilityCombinedNumberOtherHomes,
    label     : 'How many other homes do you own?',
    options: [
      { title: '0', value: 0 },
      { title: '1', value: 1 },
      { title: '2', value: 2 },
      { title: '3', value: 3 },
      { title: '4', value: 4 },
      { title: '5', value: 5 },
    ],
    showIf: [(values) => isOwningOtherHomes(values) || isPrimaryHome(values)],
  },
  {
    id        : 1060,
    component : FinicityConnect,
    name      : FieldNames.isFinancialVerificationComplete,
  },
  {
    id        : 1061,
    component : AdjustHomeValue,
    name      : FieldNames.avmHomeValue,
  },
  {
    id        : 1062,
    component : RadioField,
    name      : FieldNames.hasUniqueHomeEquityProgram,
    options   : YES_OR_NO_OPTIONS,
    title     : 'This is a unique question related to home equity programs',
  },
  {
    id       : 1063,
    component: RadioField,
    title    : 'What is your income source?',
    name     : FieldNames.employmentType,
    options  : EMPLOYMENT_TYPE_OPTIONS,
  },
  {
    id       : 1064,
    component: TextInput,
    label    : 'Company Name',
    name     : FieldNames.employCompanyName,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1065,
    component: Address,
    label    : 'Street Address',
    name     : FieldNames.employStreet,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id        : 1066,
    component : Address,
    isOptional: true,
    label     : 'Street Address 2',
    name      : FieldNames.employStreet2,
    showIf    : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1067,
    component: Address,
    label    : 'City',
    name     : FieldNames.employCity,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1068,
    component: State,
    label    : 'State',
    name     : FieldNames.employState,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1069,
    component: ZipCode,
    label    : 'Zip Code',
    name     : FieldNames.employZip,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1070,
    component: SinglePastDate,
    label    : 'Start Date',
    name     : FieldNames.employStart,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1071,
    component: Currency,
    label    : 'Monthly Base Pay',
    name     : FieldNames.employBase,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id          : 1072,
    component   : Currency,
    fieldPopover: true,
    isOptional  : true,
    label       : 'Monthly Overtime',
    name        : FieldNames.employOvertime,
    showIf      : [isEmployee, isSelfEmployed],
    popover     :
      'Overtime can be included in your income if it has been consistent over a two-year period.',
    title: 'Additional income sources?',
  },
  {
    id          : 1073,
    component   : Currency,
    fieldPopover: true,
    isOptional  : true,
    label       : 'Monthly Commission',
    name        : FieldNames.employCommission,
    showIf      : [isEmployee, isSelfEmployed],
    popover     :
      'Commissions can be included in your income if they have been consistent over a two-year period.',
  },
  {
    id          : 1074,
    component   : Currency,
    fieldPopover: true,
    isOptional  : true,
    label       : 'Monthly Bonus',
    name        : FieldNames.employBonus,
    showIf      : [isEmployee, isSelfEmployed],
    popover     :
      'Bonuses can be included in your income if they have been consistent over a two-year period.',
  },
  {
    id       : 1075,
    component: TextInput,
    label    : 'Previous Employers Name',
    name     : FieldNames.employPrevName,
    title    : 'Previous employment information',
    showIf   : [isLessThanTwoYrsAtJob],
    popover  :
    'Lenders will require previous employer information if you\'ve been with this employer for less than 2 years.',
  },
  {
    id       : 1076,
    component: Address,
    label    : 'Street Address',
    name     : FieldNames.employPrevStreet,
    showIf   : [isLessThanTwoYrsAtJob],
  },
  {
    id        : 1077,
    component : Address,
    isOptional: true,
    label     : 'Street Address 2',
    name      : FieldNames.employPrevStreet2,
    showIf    : [isLessThanTwoYrsAtJob],
  },
  {
    id       : 1078,
    component: Address,
    label    : 'City',
    name     : FieldNames.employPrevCity,
    showIf   : [isLessThanTwoYrsAtJob],
  },
  {
    id       : 1079,
    component: State,
    label    : 'State',
    name     : FieldNames.employPrevState,
    showIf   : [isLessThanTwoYrsAtJob],
  },
  {
    id       : 1080,
    component: ZipCode,
    label    : 'Zip',
    name     : FieldNames.employPrevZip,
    showIf   : [isLessThanTwoYrsAtJob],
  },
  {
    id       : 1081,
    component: SinglePastDate,
    label    : 'Start Date',
    name     : FieldNames.employPrevStart,
    showIf   : [isLessThanTwoYrsAtJob],
  },
  {
    id       : 1082,
    component: SinglePastDate,
    label    : 'End Date',
    name     : FieldNames.employPrevEnd,
    showIf   : [isLessThanTwoYrsAtJob],
    validator: {
      type : 'isAfterStartDate',
      param: FieldNames.employPrevStart,
    },
  },
  {
    id        : 1083,
    component : IncomeSources,
    name      : FieldNames.additionalIncome,
    showIf    : [isEmployee, isSelfEmployed],
  },
  {
    id         : 1084,
    component  : TextInput,
    label      : 'Job Title',
    isOptional : true,
    name       : FieldNames.employTitle,
    showIf     : [isEmployee, isSelfEmployed],
  },
  {
    id: 1085,
    ...SHARED[FieldNames.employPrevTitle],
  },
  {
    id        : 1086,
    component : RetirementIncomeSources,
    title     : 'Tell us about your retirement income',
    name      : FieldNames.retirementIncome,
    showIf    : [isRetired],
  },
  {
    id        : 1087,
    component : OtherIncomeSources,
    title     : 'Tell us about your other income',
    disclaimer: 'You are not obligated to disclose this information.',
    name      : FieldNames.otherIncome,
    showIf    : [isEmploymentOther],
    isOptional: true,
  },
  {
    id       : 1088,
    component: RadioField,
    title    : 'What is your co-borrower\'s income source?',
    name     : FieldNames.coBorrowerEmploymentType,
    options  : COBORROWER_EMPLOYMENT_TYPE_OPTIONS,
  },
  {
    id: 1089,
    component: TextInput,
    label: 'Company Name',
    name: FieldNames.coBorrowerEmployCompanyName,
    showIf: [isCoEmployee, isCoSelfEmployed],
  },
  {
    id: 1090,
    component: Address,
    label: 'Street Address',
    name: FieldNames.coBorrowerEmployStreet,
    showIf: [isCoEmployee, isCoSelfEmployed],
  },
  {
    id: 1091,
    component: Address,
    isOptional: true,
    label: 'Street Address 2',
    name: FieldNames.coBorrowerEmployStreet2,
    showIf: [isCoEmployee, isCoSelfEmployed],
  },
  {
    id: 1092,
    component: Address,
    label: 'City',
    name: FieldNames.coBorrowerEmployCity,
    showIf: [isCoEmployee, isCoSelfEmployed],
  },
  {
    id: 1093,
    component: State,
    label: 'State',
    name: FieldNames.coBorrowerEmployState,
    showIf: [isCoEmployee, isCoSelfEmployed],
  },
  {
    id: 1094,
    component: ZipCode,
    label: 'Zip Code',
    name: FieldNames.coBorrowerEmployZip,
    showIf: [isCoEmployee, isCoSelfEmployed],
  },
  {
    id: 1095,
    component: SinglePastDate,
    label: 'Start Date',
    name: FieldNames.coBorrowerEmployStart,
    showIf: [isCoEmployee, isCoSelfEmployed],
  },
  {
    id: 1096,
    component: Currency,
    label: 'Monthly Base Pay',
    name: FieldNames.coBorrowerEmployBase,
    showIf: [isCoEmployee, isCoSelfEmployed],
  },
  {
    id: 1097,
    component: TextInput,
    label: 'Previous Employers Name',
    name: FieldNames.coBorrowerEmployPrevName,
    title: 'Previous employment information',
    showIf: [isCoLessThanTwoYrsAtJob],
    popover:
      'Lenders will require previous employer information if you\'ve been with this employer for less than 2 years.',
  },
  {
    id: 1098,
    component: Address,
    label: 'Street Address',
    name: FieldNames.coBorrowerEmployPrevStreet,
    showIf: [isCoLessThanTwoYrsAtJob],
  },
  {
    id: 1099,
    component: Address,
    isOptional: true,
    label: 'Street Address 2',
    name: FieldNames.coBorrowerEmployPrevStreet2,
    showIf: [isCoLessThanTwoYrsAtJob],
  },
  {
    id: 1100,
    component: Address,
    label: 'City',
    name: FieldNames.coBorrowerEmployPrevCity,
    showIf: [isCoLessThanTwoYrsAtJob],
  },
  {
    id: 1101,
    component: State,
    label: 'State',
    name: FieldNames.coBorrowerEmployPrevState,
    showIf: [isCoLessThanTwoYrsAtJob],
  },
  {
    id: 1102,
    component: ZipCode,
    label: 'Zip',
    name: FieldNames.coBorrowerEmployPrevZip,
    showIf: [isCoLessThanTwoYrsAtJob],
  },
  {
    id: 1103,
    component: SinglePastDate,
    label: 'Start Date',
    name: FieldNames.coBorrowerEmployPrevStart,
    showIf: [isCoLessThanTwoYrsAtJob],
  },
  {
    id: 1104,
    component: SinglePastDate,
    label: 'End Date',
    name: FieldNames.coBorrowerEmployPrevEnd,
    showIf: [isCoLessThanTwoYrsAtJob],
    validator: {
      type : 'isAfterStartDate',
      param: FieldNames.coBorrowerEmployPrevStart,
    },
  },
  {
    id: 1105,
    component: Currency,
    fieldPopover: true,
    isOptional: true,
    label: 'Monthly Overtime',
    name: FieldNames.coBorrowerEmployOvertime,
    showIf: [isCoEmployee, isCoSelfEmployed],
    popover:
      'Overtime can be included in your income if it has been consistent over a two-year period.',
    title: 'Additional income sources?',
  },
  {
    id: 1106,
    component: Currency,
    fieldPopover: true,
    isOptional: true,
    label: 'Monthly Commission',
    name: FieldNames.coBorrowerEmployCommission,
    showIf: [isCoEmployee, isCoSelfEmployed],
    popover:
      'Commissions can be included in your income if they have been consistent over a two-year period.',
  },
  {
    id: 1107,
    component: Currency,
    fieldPopover: true,
    isOptional: true,
    label: 'Monthly Bonus',
    name: FieldNames.coBorrowerEmployBonus,
    showIf: [isCoEmployee, isCoSelfEmployed],
    popover:
      'Bonuses can be included in your income if they have been consistent over a two-year period.',
  },
  {
    id: 1108,
    component: IncomeSources,
    name: FieldNames.coBorrowerAdditionalIncome,
    showIf: [isCoEmployee, isCoSelfEmployed],
  },
  {
    id        : 1109,
    component : TextInput,
    label     : 'Job Title',
    isOptional: true,
    name      : FieldNames.coBorrowerEmployTitle,
    showIf    : [isCoEmployee, isCoSelfEmployed],
  },
  {
    id: 1110,
    ...SHARED[FieldNames.coBorrowerEmployPrevTitle],
  },
  {
    id       : 1111,
    component: OtherIncomeSources,
    name     : FieldNames.coBorrowerOtherIncome,
    showIf   : [isCoBorrowerEmploymentOther],
  },
  {
    id       : 1112,
    component: RetirementIncomeSources,
    name     : FieldNames.coBorrowerRetirementIncome,
    showIf   : [isCoBorrowerRetired],
  },
  {
    id       : 1113,
    component: RadioField,
    title    : 'What is your co-borrower\'s income source?',
    name     : FieldNames.coBorrowerEmploymentType,
    options  : COBORROWER_EMPLOYMENT_TYPE_OPTIONS,
  },
  {
    id        : HOME_EQUITY_TURBO_COLLECT_SSN_QUESTION,
    component : CollectRequiredSsn,
    name      : FieldNames.ssnQuestionRequirement,
  },
  {
    id        : HOME_EQUITY_TURBO_COLLECT_SSN_QUESTION_FINAL,
    component : CollectConditionallyRequiredSsn,
    name      : FieldNames.ssnQuestionRequirement,
  },
  {
    id: 1116,
    ...SHARED[FieldNames.hasAddlPropertiesYN],
    title : 'Do you own additional properties?',
  },
  {
    id       : 1117,
    component: AdditionalProperties,
    name     : FieldNames.addlProperties,
    showIf   : [hasAddlProperties],
  },
];
