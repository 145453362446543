export enum Type {
  AJAX_CONTACT_US                          = 'AJAX_CONTACT_US',
  AJAX_CONTACT_US_FAIL                     = 'AJAX_CONTACT_US_FAIL',
  AJAX_CONTACT_US_SUCCESS                  = 'AJAX_CONTACT_US_SUCCESS',
  AJAX_EMAIL_RATES                         = 'AJAX_EMAIL_RATES',
  AJAX_EMAIL_RATES_FAIL                    = 'AJAX_EMAIL_RATES_FAIL',
  AJAX_EMAIL_RATES_SUCCESS                 = 'AJAX_EMAIL_RATES_SUCCESS',
  AJAX_FETCH_AUTO_LOAN_RATES               = 'AJAX_FETCH_AUTO_LOAN_RATES',
  AJAX_FETCH_AUTO_LOAN_RATES_FAIL          = 'AJAX_FETCH_AUTO_LOAN_RATES_FAIL',
  AJAX_FETCH_AUTO_LOAN_RATES_SUCCESS       = 'AJAX_FETCH_AUTO_LOAN_RATES_SUCCESS',
  AJAX_FETCH_RATES                         = 'AJAX_FETCH_RATES',
  AJAX_FETCH_RATES_FAIL                    = 'AJAX_FETCH_RATES_FAIL',
  AJAX_FETCH_RATES_SUCCESS                 = 'AJAX_FETCH_RATES_SUCCESS',
  AJAX_FETCH_HOME_EQUITY_RATES             = 'AJAX_FETCH_HOME_EQUITY_RATES',
  AJAX_FETCH_HOME_EQUITY_RATES_FAIL        = 'AJAX_FETCH_HOME_EQUITY_RATES_FAIL',
  AJAX_FETCH_HOME_EQUITY_RATES_SUCCESS     = 'AJAX_FETCH_HOME_EQUITY_RATES_SUCCESS',
  AJAX_FETCH_OVERDRAFT_PROTECTION_RATES         = 'AJAX_FETCH_OVERDRAFT_PROTECTION_RATES',
  AJAX_FETCH_OVERDRAFT_PROTECTION_RATES_FAIL    = 'AJAX_FETCH_OVERDRAFT_PROTECTION_RATES_FAIL',
  AJAX_FETCH_OVERDRAFT_PROTECTION_RATES_SUCCESS = 'AJAX_FETCH_OVERDRAFT_PROTECTION_RATES_SUCCESS',
  AJAX_FETCH_PERSONAL_LOAN_RATES           = 'AJAX_FETCH_PERSONAL_LOAN_RATES',
  AJAX_FETCH_PERSONAL_LOAN_RATES_FAIL      = 'AJAX_FETCH_PERSONAL_LOAN_RATES_FAIL',
  AJAX_FETCH_PERSONAL_LOAN_RATES_SUCCESS   = 'AJAX_FETCH_PERSONAL_LOAN_RATES_SUCCESS',
  AJAX_FETCH_CREDIT_BOOSTER_RATES          = 'AJAX_FETCH_CREDIT_BOOSTER_RATES',
  AJAX_FETCH_CREDIT_BOOSTER_RATES_FAIL     = 'AJAX_FETCH_CREDIT_BOOSTER_RATES_FAIL',
  AJAX_FETCH_CREDIT_BOOSTER_RATES_SUCCESS  = 'AJAX_FETCH_CREDIT_BOOSTER_RATES_SUCCESS',
  AJAX_FETCH_CONSUMER_LOAN_LIMITS          = 'AJAX_FETCH_CONSUMER_LOAN_LIMITS',
  AJAX_FETCH_CONSUMER_LOAN_LIMITS_FAIL     = 'AJAX_FETCH_CONSUMER_LOAN_LIMITS_FAIL',
  AJAX_FETCH_CONSUMER_LOAN_LIMITS_SUCCESS  = 'AJAX_FETCH_CONSUMER_LOAN_LIMITS_SUCCESS',
  AJAX_GET_PARTIAL_APP                     = 'AJAX_GET_PARTIAL_APP',
  AJAX_GET_PARTIAL_APP_FAIL                = 'AJAX_GET_PARTIAL_APP_FAIL',
  AJAX_GET_PARTIAL_APP_SUCCESS             = 'AJAX_GET_PARTIAL_APP_SUCCESS',
  AJAX_GET_HOME_EQUITY_PREFILL             = 'AJAX_GET_HOME_EQUITY_PREFILL',
  AJAX_GET_HOME_EQUITY_PREFILL_SUCCESS     = 'AJAX_GET_HOME_EQUITY_PREFILL_SUCCESS',
  AJAX_GET_HOME_EQUITY_PREFILL_FAIL        = 'AJAX_GET_HOME_EQUITY_PREFILL_FAIL',
  AJAX_GET_PREQUAL_LETTER                  = 'AJAX_GET_PREQUAL_LETTER',
  AJAX_GET_PREQUAL_LETTER_FAIL             = 'AJAX_GET_PREQUAL_LETTER_FAIL',
  AJAX_GET_PREQUAL_LETTER_SUCCESS          = 'AJAX_GET_PREQUAL_LETTER_SUCCESS',
  AJAX_GET_PREQUAL_RATES                   = 'AJAX_GET_PREQUAL_RATES',
  AJAX_GET_PREQUAL_RATES_FAIL              = 'AJAX_GET_PREQUAL_RATES_FAIL',
  AJAX_GET_PREQUAL_RATES_SUCCESS           = 'AJAX_GET_PREQUAL_RATES_SUCCESS',
  AJAX_GET_SB_RATES                        = 'AJAX_GET_SB_RATES',
  AJAX_GET_SB_RATES_FAIL                   = 'AJAX_GET_SB_RATES_FAIL',
  AJAX_GET_SB_RATES_SUCCESS                = 'AJAX_GET_SB_RATES_SUCCESS',
  AJAX_POST_CHECKLIST                      = 'AJAX_POST_CHECKLIST',
  AJAX_POST_CHECKLIST_FAIL                 = 'AJAX_POST_CHECKLIST_FAIL',
  AJAX_POST_CHECKLIST_SUCCESS              = 'AJAX_POST_CHECKLIST_SUCCESS',
  AJAX_POST_MORTGAGE_APP                   = 'AJAX_POST_MORTGAGE_APP',
  AJAX_POST_MORTGAGE_APP_FAIL              = 'AJAX_POST_MORTGAGE_APP_FAIL',
  AJAX_POST_MORTGAGE_APP_SUCCESS           = 'AJAX_POST_MORTGAGE_APP_SUCCESS',
  AJAX_POST_PARTIAL_APP                    = 'AJAX_POST_PARTIAL_APP',
  AJAX_POST_PARTIAL_APP_FAIL               = 'AJAX_POST_PARTIAL_APP_FAIL',
  AJAX_POST_PARTIAL_APP_SUCCESS            = 'AJAX_POST_PARTIAL_APP_SUCCESS',
  AJAX_POST_PREQUAL_LETTER                 = 'AJAX_POST_PREQUAL_LETTER',
  AJAX_POST_PREQUAL_LETTER_FAIL            = 'AJAX_POST_PREQUAL_LETTER_FAIL',
  AJAX_POST_PREQUAL_LETTER_SUCCESS         = 'AJAX_POST_PREQUAL_LETTER_SUCCESS',
  AJAX_POST_USER_DATA                      = 'AJAX_POST_USER_DATA',
  AJAX_POST_USER_DATA_FAIL                 = 'AJAX_POST_USER_DATA_FAIL',
  AJAX_POST_USER_DATA_SUCCESS              = 'AJAX_POST_USER_DATA_SUCCESS',
  AJAX_FETCH_EXPRESS_RATES                 = 'AJAX_FETCH_EXPRESS_RATES',
  AJAX_PUT_PARTIAL_APP                     = 'AJAX_PUT_PARTIAL_APP',
  AJAX_PUT_PARTIAL_APP_FAIL                = 'AJAX_PUT_PARTIAL_APP_FAIL',
  AJAX_PUT_PARTIAL_APP_SUCCESS             = 'AJAX_PUT_PARTIAL_APP_SUCCESS',
  AJAX_POST_HOME_EQUITY_TURBO              = 'AJAX_POST_HOME_EQUITY_TURBO',
  AJAX_POST_HOME_EQUITY_TURBO_FAIL         = 'AJAX_POST_HOME_EQUITY_TURBO_FAIL',
  AJAX_POST_HOME_EQUITY_TURBO_SUCCESS      = 'AJAX_POST_HOME_EQUITY_TURBO_SUCCESS',
  AJAX_POST_PURCHASE_TURBO                = 'AJAX_POST_PURCHASE_TURBO',
  AJAX_POST_PURCHASE_TURBO_FAIL           = 'AJAX_POST_PURCHASE_TURBO_FAIL',
  AJAX_POST_PURCHASE_TURBO_SUCCESS        = 'AJAX_POST_PURCHASE_TURBO_SUCCESS',
  AJAX_POST_REFINANCE_TURBO                = 'AJAX_POST_REFINANCE_TURBO',
  AJAX_POST_REFINANCE_TURBO_FAIL           = 'AJAX_POST_REFINANCE_TURBO_FAIL',
  AJAX_POST_REFINANCE_TURBO_SUCCESS        = 'AJAX_POST_REFINANCE_TURBO_SUCCESS',
  DETERMINE_PREQUAL_TRANSITION             = 'DETERMINE_PREQUAL_TRANSITION',
  GET_NEXT_SECTION                         = 'GET_NEXT_SECTION',
  GET_PREVIOUS_SECTION                     = 'GET_PREVIOUS_SECTION',
  GO_TO_LONG_FORM                          = 'GO_TO_LONG_FORM',
  GO_TO_SECTION                            = 'GO_TO_SECTION',
  HANDLE_AUTOLOAN_SUBMIT                   = 'HANDLE_AUTOLOAN_SUBMIT',
  HANDLE_AUTO_PREQUAL_GET_RATES            = 'HANDLE_AUTO_PREQUAL_GET_RATES',
  HANDLE_AUTO_PREQUAL_RECALCULATE_RATES    = 'HANDLE_AUTO_PREQUAL_RECALCULATE_RATES',
  HANDLE_AUTO_PREQUAL_SUBMIT               = 'HANDLE_AUTO_PREQUAL_SUBMIT',
  HANDLE_AVM_RESPONSE                      = 'HANDLE_AVM_RESPONSE',
  HANDLE_COMMERCIAL_LOAN_TYPE_SELECT       = 'HANDLE_COMMERCIAL_LOAN_TYPE_SELECT',
  HANDLE_CONSUMER_APPLICATION_SUBMIT       = 'HANDLE_CONSUMER_APPLICATION_SUBMIT',
  HANDLE_CONTACT_FORM_SUBMIT               = 'HANDLE_CONTACT_FORM_SUBMIT',
  HANDLE_HOMEEQUITY_LONGFORM_SUBMIT_FIRST  = 'HANDLE_HOMEEQUITY_LONGFORM_SUBMIT_FIRST',
  HANDLE_HOMEEQUITY_LONGFORM_SUBMIT_SECOND = 'HANDLE_HOMEEQUITY_LONGFORM_SUBMIT_SECOND',
  HANDLE_HOMEEQUITY_SUBMIT_FIRST           = 'HANDLE_HOMEEQUITY_SUBMIT_FIRST',
  HANDLE_HOMEEQUITY_SUBMIT_SECOND          = 'HANDLE_HOMEEQUITY_SUBMIT_SECOND',
  HANDLE_LONGFORM_SUBMIT                   = 'HANDLE_LONGFORM_SUBMIT',
  HANDLE_LONGFORM_TYPE_SELECT              = 'HANDLE_LONGFORM_TYPE_SELECT',
  HANDLE_PERSONAL_LOAN_SUBMIT              = 'HANDLE_PERSONAL_LOAN_SUBMIT',
  HANDLE_RECALCULATE_RATES                 = 'HANDLE_RECALCULATE_RATES',
  HANDLE_REFINANCE_TURBO_ADJUSTMENT        = 'HANDLE_REFINANCE_TURBO_ADJUSTMENT',
  HANDLE_REFINANCE_TURBO_SUBMIT_SECOND     = 'HANDLE_REFINANCE_TURBO_SUBMIT_SECOND',
  HANDLE_SAVE_PARTIAL                      = 'HANDLE_SAVE_PARTIAL',
  HANDLE_SHORTFORM_RATE_SELECT             = 'HANDLE_SHORTFORM_RATE_SELECT',
  HANDLE_SHORTFORM_SUBMIT                  = 'HANDLE_SHORTFORM_SUBMIT',
  HANDLE_TURBO_SUBMIT                      = 'HANDLE_TURBO_SUBMIT',
  MODAL_PARTIAL_CLOSE                      = 'MODAL_PARTIAL_CLOSE',
  PUSH_NOTIFICATION                        = 'PUSH_NOTIFICATION',
  REFER_TO_LOAN_OFFICER                    = 'REFER_TO_LOAN_OFFICER',
  REMOVE_SUBJECT_PROPERTY_ADDRESS          = 'REMOVE_SUBJECT_PROPERTY_ADDRESS',
  REMOVE_STREET_ADDRESSES                  = 'REMOVE_STREET_ADDRESSES',
  SET_DISPLAY_MODE                         = 'SET_DISPLAY_MODE',
  SET_FORM_INITIAL_VALUES                  = 'SET_FORM_INITIAL_VALUES',
  SET_LOCALE                               = 'SET_LOCALE',
  SET_PREQUAL_RATE                         = 'SET_PREQUAL_RATE',
  SET_RESIDENCE_ADDRESS                    = 'SET_RESIDENCE_ADDRESS',
  SET_FORM_CONDITIONALS                    = 'SET_FORM_CONDITIONALS',
  SET_SELECTED_RATE                        = 'SET_SELECTED_RATE',
  SET_SUBMITTED_DATA                       = 'SET_SUBMITTED_DATA',
  UPDATE_NOTIFICATION                      = 'UPDATE_NOTIFICATION',
}

