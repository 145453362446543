import { getLoanAmount } from 'app/api/helpers';
import moment from 'moment';
import i18next from 'i18next';
import { getFormInitialValues, getFormValues } from 'redux-form';
import { FieldLabels } from 'app/models/fields/labels';
import { FieldNames } from 'app/models/fields/names';
import { getStore } from 'app/store';
import { isFromDealer, isAutoDownPaymentLow } from '../models/fields/conditionals';
import { toDollar } from 'app/util/formatters';
import { AutoMinLtvAction, getDownPaymentMin, getDownPaymentMinPercent } from 'app/reducers/app-config';
import { getFormName } from 'app/routes/helpers';

type ValidationResponse = string | undefined;

/*
 * REDUX-FORM VALIDATORS
 *
 * These are functions that are passed into the "validate"
 * param on an <Input /> component. These should return undefined
 * when the input is valid, or an error message as a string if
 * invalid.
 *
 * See: https://goo.gl/NCCsF6
 *
 * @param {any} value The value of the input field
 * @param {any[]} allValues All values in the form
 * @param {object} props All props passed to the form
 * @param {string} name Name of the field being validated
 *
 * @returns {ValidationResponse} String containing error message
 */

/**
 * Validator to specify a field is required
 *
 * @param {any} value
 * @returns {ValidationResponse}
 */
export const required = (value: any): ValidationResponse => {
  const errorMessage = i18next.t('validation:error.required', {
    defaultValue: 'Required',
  });
  return value ? undefined : errorMessage;
};

/**
 * Validator to specify if a user must focus on a field
 * before continuing
 *
 * @param {*} value
 * @returns {ValidationResponse}
 */
export const requiredFocus = (value: any): ValidationResponse => {
  const errorMessage = i18next.t('validation:error.requiredFocus', {
    defaultValue: 'Required',
  });
  return typeof value !== 'undefined' ? undefined: errorMessage;
};

/**
 * Takes in a list of keys and validates the corresponding values
 * in a field array exist
 *
 * @param {string[]} keys
 * @returns {ValidationResponse}
 */
export const requiredArray = (keys: string[]) => (values: any = []): ValidationResponse => {
  const errorMessage = i18next.t('validation:error.requiredArray', {
    defaultValue: 'Required',
  });
  const isValid = values.every((value) => {
    return keys.every((key) => {
      return value[key];
    });
  });
  return isValid ? undefined : errorMessage;
};

/**
 * Validates whether or not the field value is greater than
 * the min value.
 *
 * Usage: validate={minValue(1000)}
 *
 * @param {number} min Minimum value
 * @returns {ValidationResponse}
 */
export const isGreaterThan = (min: number) => (value: number): ValidationResponse => {
  const errorMessage = i18next.t('validation:error.isGreaterThan', {
    minValue    : min.toLocaleString(),
    defaultValue: 'Must be at least ${{ minValue }}',
  });
  return value && value < min
    ? errorMessage
    : undefined;
};

/**
 * Validates whether or not the field value is both greater than the value in the
 * min field and less than the value in the max field
 *
 * Usage: validate={isBetween(['minLoanAmount', 'maxLoanAmount'])}
 *
 * @param {[string, string]} minAndMaxFields 0th element is name of min field, 1st element is name of max field
 * @returns {ValidationResponse}
 */
export const isBetween = (minAndMaxFields: [string, string]) => {
  return (value: number, allValues: any[]): ValidationResponse => {
    const minValue = allValues[minAndMaxFields[0]];
    const maxValue = allValues[minAndMaxFields[1]];
    const errorMessage = i18next.t('validation:error.isBetween', {
      minValue,
      maxValue,
      defaultValue: 'Must be between ${{ minValue }} and ${{ maxValue }}',
    });
    return value && (value < minValue || value > maxValue) ? errorMessage : undefined;
  };
};

/**
 * Validates whether or not the field value is both greater than the min and less than the max
 *
 * Usage: validate={isBetweenValues([500, 5000])}
 *
 * @param {[number, number]} minAndMaxValues 0th element is min value, 1st element is max value
 * @returns {ValidationResponse}
 */
export const isBetweenValues = (minAndMaxValues: [number, number]) => {
  return (value: number): ValidationResponse => {
    const minValue = minAndMaxValues[0];
    const maxValue = minAndMaxValues[1];
    const errorMessage = i18next.t('validation:error.isBetween', {
      minValue,
      maxValue,
      defaultValue: 'Must be between ${{ minValue }} and ${{ maxValue }}',
    });
    return value && (value < minValue || value > maxValue) ? errorMessage : undefined;
  };
};

/**
 * Validate whether or not the field value is greater
 * than or equal to the other field specified
 *
 * @param {string} otherField Field containing minimum value
 * @returns {ValidationResponse}
 */
export const isGreaterThanField = (otherField: string) => {
  return (value: number, allValues: any[]): ValidationResponse => {
    const errorMessage = i18next.t('validation:error.isGreaterThanField', {
      otherField  : FieldLabels[otherField],
      defaultValue: 'Must be greater than {{ otherField }}',
    });
    return value >= allValues[otherField]
      ? undefined
      : errorMessage;
  };
};

/**
 * Validate if the total loan amount is greater than the
 * minimum loan amount set for a client
 *
 * @param {*} value
 * @param {*} allValues
 * @returns {ValidationResponse}
 */
export const isGreaterThanMinLoan = (value: any, allValues: any): ValidationResponse => {
  const state           = getStore().getState();
  const formName        = getFormName(state.router.location.pathname);
  const formValues      = getFormValues(formName)(state);

  const clientMinLoan   = state.config ? state.config.clientMinLoan || 0 : 0;
  const helocMinLoan    = formValues[FieldNames.minLoanAmount] ? formValues[FieldNames.minLoanAmount] : 0;
  const isHelocForm     = state.form.homeEquity || state.form.homeEquityLongForm || state.form.homeEquityExpress;
  const minLoanAmount   = isHelocForm ? helocMinLoan: clientMinLoan;
  const totalLoanAmount = getLoanAmount(allValues);

  const errorMessage    = i18next.t('validation:error.isGreaterThanMinLoan', {
    minLoanAmount: minLoanAmount.toLocaleString(),
    defaultValue : 'Loan amount must be at least ${{ minLoanAmount }}',
  });

  return totalLoanAmount >= minLoanAmount
    ? undefined
    : errorMessage;
};

/**
 * This method checks to see if the down payment is too low for the given form.
 *
 * It can be too low based on the minimum down payment defined by the client.  Or by the
 * minimum down payment percentage of the loan, also defined by the client.
 *
 * @param {any} value
 * @param {any} allValues
 * @returns {ValidationResponse}
 */
export const isDownPaymentTooLow = (value: any, allValues: any): ValidationResponse => {
  const state = getStore().getState();
  const downPaymentMin = getDownPaymentMin(state);
  const downPaymentMinPercent = getDownPaymentMinPercent(state);

  const homeValue = allValues[FieldNames.homeValue];
  const downPayment = allValues[FieldNames.downDollar];

  // check down payment percentage minimum for client
  if ((downPayment / homeValue) < (downPaymentMinPercent / 100)) {
    const downPaymentTooLowPercentErrorMessage = i18next.t('validation:error.isDownPaymentTooLowPercent', {
      downPaymentMinPercent,
      defaultValue : 'A {{ downPaymentMinPercent, percent.0 }} down payment is required; please contact us for other options',
    });
    return downPaymentTooLowPercentErrorMessage;
  }

  // check down payment minimum for client
  if (downPayment < downPaymentMin) {
    const downPaymentTooLowErrorMessage = i18next.t('validation:error.isDownPaymentTooLow', {
      downPaymentMin,
      defaultValue   : 'A {{ downPaymentMin, currency.0 }} down payment is required; please contact us for other options',
    });
    return downPaymentTooLowErrorMessage;
  }

  // no errors
  return undefined;
};

/**
 * Validate whether or not the field value is less
 * than or equal to the other field specified
 *
 * @param {string} otherField Field containing maximum value
 * @returns {ValidationResponse}
 */
export const isLessThanField = (otherField: string) => {
  return (value: number, allValues: any[]): ValidationResponse => {
    const errorMessage = i18next.t('validation:error.isLessThanField', {
      otherField  : FieldLabels[otherField],
      defaultValue: 'Must be less than {{ otherField }}',
    });
    return value <= allValues[otherField]
      ? undefined
      : errorMessage;
  };
};

/**
 * Validates a string as an email address
 *
 * @param {string} value Form input value
 * @returns {ValidationResponse}
 */
export const isEmail = (value: string): ValidationResponse => {
  const errorMessage = i18next.t('validation:error.isEmail', {
    defaultValue: 'Invalid email address',
  });
  return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? errorMessage
    : undefined;
};

/**
 * Validates if the value is a number
 *
 * @param {*} value Form input value
 * @returns {ValidationResponse}
 */
export const isNumber = (value: any): ValidationResponse => {
  const errorMessage = i18next.t('validation:error.isNumber', {
    defaultValue: 'Must be a number',
  });
  return value && isNaN(Number(value)) ? errorMessage : undefined;
};

/**
 * Validates if the value length is greater than min
 *
 * @param min Minimum length
 * @returns {ValidationResponse}
 */
export const minLength = (min: number) => (value): ValidationResponse => {
  const errorMessage = i18next.t('validation:error.minLength', {
    valueLength : min,
    defaultValue: 'Must be at least {{ valueLength }} characters',
  });
  return value && value.length < min
    ? errorMessage
    : undefined;
};

/**
 * Used as a placeholder when a custom validator can't be found.
 * This doesn't do any validation, but makes sure that undefined is
 * returned
 *
 * @param {*} [param] Any param
 * @returns {undefined}
 */
export const noopValidate = (param?: any): undefined => {
  return undefined;
};

/**
 * Validates user input to see if phone considers it to be a valid phone
 * @param {string} number
 * @returns {ValidationRespone}
 */
export const isValidPhone = (number: string): ValidationResponse => {
  const errorMessage = i18next.t('validation:error.isValidPhone', {
    defaultValue: 'Invalid phone number',
  });
  const phoneFormat = /((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/; /* (123) 456-7890 */
  const matchesForm = phoneFormat.test(number);
  return (!number || matchesForm) ? undefined : errorMessage;
};

/**
 * Validates user input to see if number is a valid social security number
 *
 * @param {string} number
 * @returns {ValidationRespone}
 */
export const isValidSsn = (number: string): ValidationResponse => {
  const errorMessage = i18next.t('validation:error.isValidSsn', {
    defaultValue: 'Invalid social security number',
  });
  const ssnFormat = /\d{9}/; /* 9 digits long */
  const matchesForm = ssnFormat.test(number);
  return (!number || matchesForm) ? undefined : errorMessage;
};

/**
 * Validates user input to see if moment considers the input a valid date
 *
 * @param {*} date
 * @returns {ValidationResponse}
 */
export const isValidDate = (date: any): ValidationResponse => {
  if(date) {
    // Validates that the input uses the year 1900 or later
    const minYear      = moment('1900-01-01').format();
    const isValidYear  = moment(date).isSameOrAfter(minYear);
    const errorMessage = i18next.t('validation:error.isValidDate', {
      defaultValue: 'Invalid Date',
    });

    return isValidYear && moment(date).isValid() ? undefined : errorMessage;
  }
  return undefined;
};

/**
 * Validates user input to see if moment considers the year valid and after
 * the year 1800 but not past the current year.  This validation is not run
 * if we pass in undefined. (i.e year is optional)
 *
 * @param {unknown | undefined} year
 * @returns {ValidationResponse}
 */
export const isValidYear = (year: unknown | undefined): ValidationResponse => {
  if (year) { // don't validate if undefined
    const firstYear    = moment().year(1800);
    const thisYear     = moment();
    const inputtedYear = moment().year(year as number);
    const isValidYear  = inputtedYear.isBetween(firstYear, thisYear, 'year', '[]');
    const errorMessage = i18next.t('validation:error.isValidYear', {
      defaultValue: `Please enter a year between ${firstYear.year()} and ${thisYear.year()}.`,
    });
    return isValidYear ? undefined : errorMessage;
  }
};

/**
 * Validates if the date value is before today
 *
 * @param {*} date
 * @returns {ValidationResponse}
 */
export const isDateInPast = (date: any): ValidationResponse => {
  const errorMessage = i18next.t('validation:error.isDateInPast', {
    defaultValue: 'Must be before today\'s date',
  });
  /* If the question is optional, do not validate against non values */
  if (date) {
    const today = moment();
    const entry = moment(date).format();
    return moment(entry).isBefore(today) ? undefined : errorMessage;
  }
  return undefined;
};

/**
 * Validates if the date value is after today
 *
 * @param {*} date
 * @returns {ValidationResponse}
 */
export const isDateInFuture = (date: any): ValidationResponse => {
  const errorMessage = i18next.t('validation:error.isDateInFuture', {
    defaultValue: 'Must be after today\'s date',
  });
  /* If the question is optional, do not validate against non values */
  if (date) {
    const today = moment();
    const entry = moment(date).format();
    return moment(entry).isAfter(today) ? undefined : errorMessage;
  }
  return undefined;
};

/**
 * Validates that the end date is after the start date.
 *
 * @param {number} endDateValue
 * @param {*} allValues
 * @returns {ValidationResponse}
 */
export const isAfterStartDate = (startDateField: string) => (
  endDateValue: number,
  allValues: any,
): ValidationResponse => {

  const errorMessage = i18next.t('validation:error.isAfterStartDate', {
    defaultValue: 'Must be after the Start Date',
  });

  const startDateValue: any = allValues[startDateField];
  if (startDateValue && endDateValue) {
    return moment(endDateValue).isAfter(startDateValue) ? undefined : errorMessage;
  }
  return undefined;
};

/**
 * Validates if the total loan amount is less than
 * the property value AND validates that the loan amount
 * is greater than the client min loan value
 *
 * @param {number} value
 * @param {*} allValues
 * @returns {ValidationResponse}
 */
export const loanAmountIsValid = (otherField: string) => (
  value: number,
  allValues: any,
): ValidationResponse => {
  return (
    isLessThanField(otherField)(value, allValues) ||
    isGreaterThanMinLoan(value, allValues)
  );
};

/**
 * Validates if the entered downDollar is greater than
 * the inital downDollar value.
 *
 * @param {number} value
 * @returns {ValidationResponse}
 */
export const downPaymentGreaterThanInitial = (value: number): ValidationResponse => {
  const errorMessage = i18next.t('validation:error.downPaymentGreaterThanInitial', {
    defaultValue: 'Down payment can only be increased',
  });
  const state         = getStore().getState();
  const formName      = getFormName(state.router.location.pathname);
  const initialValues = getFormInitialValues(formName)(state);
  return value >= initialValues[FieldNames.downDollar] ? undefined : errorMessage;
};

/**
 * Validates requested auto loan according to the purchase price, trade-in, and down payment.
 *
 * @param {number} value
 * @param {any} allValues
 * @returns {ValidationResponse}
 */
export const isAutoLoanValid = () => (value: number, allValues: any): ValidationResponse => {
  const state = getStore().getState();

  let errorMessageText  = '';
  const isDealerText    = isFromDealer(allValues) ? '+ Trade-In '  : '';

  const purchasePrice              = allValues[FieldNames.purchasePrice];
  const downPayment                = allValues[FieldNames.downDollar];
  const maxLoanAmount              = allValues[FieldNames.maxLoanAmount];
  const minLoanAmount              = allValues[FieldNames.minLoanAmount];
  const autoLoanMinLtvAction       = allValues[FieldNames.autoLoanMinLtvAction];

  const autoLoanMinLtvPercent      = getDownPaymentMinPercent(state);

  const tradeIn         = isFromDealer(allValues) && allValues[FieldNames.tradeInValue] ? allValues[FieldNames.tradeInValue] : 0;
  const finalLoanAmount = purchasePrice - downPayment - tradeIn;

  if (isAutoDownPaymentLow(allValues, autoLoanMinLtvPercent) && autoLoanMinLtvAction === AutoMinLtvAction.DISPLAY_ERROR) {
    errorMessageText = `Down Payment ${isDealerText}is not at least ${autoLoanMinLtvPercent}% of the purchase price`;
  } else if (finalLoanAmount < minLoanAmount || finalLoanAmount > maxLoanAmount) {
    errorMessageText = `Your final loan amount is not between the required limits of ${toDollar(minLoanAmount)} and ${toDollar(maxLoanAmount)}`;
  }

  const isAutoLoanValid: boolean =  errorMessageText === '';
  const errorMessage = i18next.t('validation:error.isAutoLoanValid', {
    defaultValue: errorMessageText,
  });

  return isAutoLoanValid ? undefined : errorMessage;
};
/**
 * This function compares one value against a different value otherFieldName
 * and returns an error if they are equal
 *
 * @param {string} otherFieldName
 * @returns {ValidationResponse}
 */
export const isNotEqual = (otherFieldName: string) => {
  return (value: string, allValues: any): ValidationResponse => {

    let messageText = '';
    const areValuesEqual = value === allValues[otherFieldName];

    if(otherFieldName === FieldNames.email) {
      messageText = 'Co-Borrower email must be different from the Borrower\'s email';
    }
    const errorMessage = i18next.t('validation:error.isNotEqual', {
      defaultValue: messageText,
    });

    return areValuesEqual ? errorMessage : undefined;
  };
};

/**
 * This function compares the field value against a set of possible "PO BOX" variations
 * in order to prevent the user from entering a PO BOX address.
 *
 * @param streetField Street address line #1 or line #2
 * @returns {ValidationResponse}
 */
export const isMailingAddress = (streetField: string) => (value: string): ValidationResponse => {
  const errorMessage = i18next.t('validation:error.isMailingAddress', {
    fieldName: FieldLabels[streetField],
    defaultValue: '{{ fieldName }} cannot be a PO Box',
  });

  const poboxes = [
    'po box ',
    'pobox ',
    'p.o. ',
    'p o box ',
    'p. o. box ',
    'p.o.box ',
  ];
  return (value && poboxes.some(pobox => value.toLowerCase().match(pobox))) ? errorMessage : undefined;
};

/**
 * Validates whether or not the field value is a value between 0 and 101
 *
 * Usage:
 *  validator: {
      type : 'isPercentage',
      param: FieldNames.buildingSquareFootage,
    },
 *
 * @returns {ValidationResponse}
 */
export const isPercentage = () => (value: number): ValidationResponse => {
  const errorMessage = i18next.t('validation:error.isPercentage', {
    defaultValue: 'Must be a percentage > 0 and <= 100',
  });
  return value && (value <= 100 && value > 0) ? undefined : errorMessage;
};

/**
 * Validates whether or not the field value is greater than 0
 *
 * Usage:
 *  validator  : {
      type  : 'isGreaterThanZero',
      param : FieldNames.landSize,
    },
 *
 * @returns {ValidationResponse}
 */
export const isGreaterThanZero = () => (value: number): ValidationResponse => {
  const errorMessage = i18next.t('validation:error.isGreaterThanZero', {
    defaultValue: 'Must be a value greater than 0',
  });
  return value && ( value > 0) ? undefined : errorMessage;
};

const MAX_SMALL_BUSINESS_LOAN_AMOUNT = 250000;
const MIN_SMALL_BUSINESS_LOAN_AMOUNT = 25000;

/**
 * Validates if the loan amount is between the required values
 *
 * @param {number} value
 * @returns {ValidationResponse}
 */
export const isSmallBusinessLoanAmountValid = () => (value: number): ValidationResponse => {
  const errorMessage = i18next.t('validation:error.isNotAValidSmallBusinessLoanAmount', {
    defaultValue: `Must be between
    $${MIN_SMALL_BUSINESS_LOAN_AMOUNT.toLocaleString("en-US")} and
    $${MAX_SMALL_BUSINESS_LOAN_AMOUNT.toLocaleString("en-US")}`,
  });
  return value >= MIN_SMALL_BUSINESS_LOAN_AMOUNT &&
  value <= MAX_SMALL_BUSINESS_LOAN_AMOUNT ? undefined : errorMessage;
};

/**
 * Validates the array to make sure it contains at least one item.  Useful
 * for checkboxes to ensure at least one is checked at minumum.
 *
 * @param {string[]} array
 * @returns {string | undefined}
 */
export const hasAtLeastOneChecked = (array: string[]): string | undefined => {
  return array?.length === 0 ? 'Must choose at least one option' : undefined;
};

/**
 * Validates whether or not the field value is a value between 0 and 60
 *
 * Usage:
 *  validator: {
      type : 'isLineOfWorkYears',
      param: FieldNames.lineOfWorkYears,
    },
 *
 * @returns {ValidationResponse}
 */
export const isLineOfWorkYears = () => (value: number): ValidationResponse => {
  const errorMessage = i18next.t('validation:error.isValidMonthCount', {
    defaultValue: 'Must be a valid number of years ( <= 60 )',
  });
  return typeof value === 'undefined' || (value <= 60 && value >= 0) ? undefined : errorMessage;
};

/**
 * Validates whether or not the field value is a value between 0 and 11
 * @returns {ValidationResponse}
 */
export const isLineOfWorkMonths = () => (value: number): ValidationResponse => {
  const errorMessage = i18next.t('validation:error.isValidMonthCount', {
    defaultValue: 'Must be a valid number of months ( < 12 )',
  });
  return typeof value === 'undefined' || (value < 12 && value >= 0) ? undefined : errorMessage;
};

/**
 * Validate whether or not the DynamicComponent field value is greater
 * than or equal to the other field specified
 *
 * @param {string} param = arrayName-index-otherField
 * @returns {ValidationResponse}
 */
export const isGreaterThanFieldDynamicComponent = (param: string) => {
  const paramArray = param.split('-');
  const arrayName  = paramArray[0];
  const index      = paramArray[1];
  const otherField = paramArray[2];

  return (value: number, allValues: any[]): ValidationResponse => {
    const errorMessage = i18next.t('validation:error.isGreaterThanField', {
      otherField  : FieldLabels[otherField],
      defaultValue: 'Must be greater than {{ otherField }}',
    });
    return value >= allValues[arrayName]?.[index]?.[otherField]
      ? undefined
      : errorMessage;
  };
};

/**
 * Object containing all validators, this is used
 * when you want to dynamically inject validators.
 *
 * @TODO: Example usage
 */
export const VALIDATORS = {
  isEmail,
  isGreaterThan,
  isGreaterThanField,
  isGreaterThanMinLoan,
  isGreaterThanZero,
  isBetween,
  isBetweenValues,
  isLessThanField,
  isLineOfWorkMonths,
  isLineOfWorkYears,
  isMailingAddress,
  isNotEqual,
  isNumber,
  isPercentage,
  isSmallBusinessLoanAmountValid,
  loanAmountIsValid,
  minLength,
  noopValidate,
  required,
  downPaymentGreaterThanInitial,
  isAfterStartDate,
  isAutoLoanValid,
  hasAtLeastOneChecked,
  isGreaterThanFieldDynamicComponent,
};

/**
 * FORM-LEVEL VALIDATORS
 *
 * These are functions that are passed into the "validate"
 * param on the `reduxForm({})` decorator. These should return an
 * empty object when the input is valid.
 *
 * @see https://redux-form.com/8.2.2/docs/api/reduxform.md/#-code-validate-values-object-props-object-gt-errors-object-code-optional-
 *
 * @param {any} values The values of the form
 * @param {object} props All props passed to the form
 */
