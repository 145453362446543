import { client } from 'app/api/_client';
import { Endpoints } from 'app/api/helpers';
import { logger } from 'app/util/logger';
import { LiabilityUsage } from 'app/models/options/enums';
import { LiabilityStatus } from '@lenderful/domain';

export interface SoftCreditPullPayload {
  creditReportId?       : number;
  formName              : string;
}
export interface SoftCreditPullResponse {
  creditReportId        : number;
  isComplete            : boolean;
  hasReferral           : boolean;
  realEstateLiabilities : LiabilityAccount[];
}

export interface LiabilityAccount {
  liabilityAmount         : number;
  liabilityBalance        : number;
  liabilityName           : string;
  liabilityStatus?        : LiabilityStatus;
  liabilityUsage?         : LiabilityUsage;
  liabilityYearAssociate? : number;
  liabilityYearInsure?    : number;
  liabilityYearTaxes?     : number;
}

export interface ResponseError {
  type   : string;
  message: string;
}

export const postCreditReport = (body) => {
  return client.post(Endpoints.CREDIT_REPORT, body)
    .then((response) => {
      if (response.data) {
        return response.data as SoftCreditPullResponse;
      } else {
        logger.error('Error in Credit Report API call: ', response);
        throw response;
      }
    })
    .catch((error) => {
      throw error;
    });
};
