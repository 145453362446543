import { FieldNames } from '../../models/fields/names';
import {
  hasFSCashAccount,
  hasFSCreditCard,
  hasFSLifeInsurance,
  hasFSLoan,
  hasFSMortgage,
  hasFSNote,
  hasFSPersonalProperty,
  hasFSRealEstate,
  hasFSRetirementAccount,
  hasFSStock,
  hasFSTax,
} from '../../models/fields/conditionals';
import {
  FinancialStatement,
  FinancialStatementCashAccount,
  FinancialStatementCreditCard,
  FinancialStatementLifeInsurance,
  FinancialStatementLoansPayable,
  FinancialStatementMortgages,
  FinancialStatementNotesAndContracts,
  FinancialStatementPersonalProperty,
  FinancialStatementRealEstate,
  FinancialStatementRetirementAccount,
  FinancialStatementStock,
  FinancialStatementTaxesPayable,
} from '@lenderful/domain';

export const mapFinancialStatement = (formData) => {
  const { cashAccounts, totalCashAccountValue }      = mapCashAccounts(formData);
  const { creditCards, totalCreditCardValue }        = mapCreditCards(formData);
  const { lifeInsurance, totalLifeInsuranceValue }   = mapLifeInsurance(formData);
  const { loans, totalLoansValue }                   = mapLoans(formData);
  const { mortgages, totalMortgagesValue }           = mapMortgages(formData);
  const { notesAndContracts, totalNotesValue }       = mapNotesAndContracts(formData);
  const { personalProperties, totalPersonalValue }   = mapPersonalProperty(formData);
  const { realEstate, totalRealEstateValue }         = mapRealEstate(formData);
  const { retirementAccounts, totalRetirementValue } = mapRetirementAccounts(formData);
  const { stocks, totalStockValue }                  = mapStocks(formData);
  const { taxes, totalTaxValue }                     = mapTaxes(formData);

  const financialStatementRequest: FinancialStatement = {
    dateOfBirth     : formData[FieldNames.dateOfBirth],
    email           : formData[FieldNames.email],
    street          : formData[FieldNames.livingPropertyStreet],
    street2         : formData[FieldNames.livingPropertyStreet2],
    city            : formData[FieldNames.livingPropertyCity],
    state           : formData[FieldNames.livingPropertyState],
    zipCode         : formData[FieldNames.livingPropertyZip],
    firstName       : formData[FieldNames.firstName],
    middleName      : formData[FieldNames.middleName],
    lastName        : formData[FieldNames.lastName],
    suffixName      : formData[FieldNames.suffixName],
    businessName    : formData[FieldNames.commercialBusinessName],
    businessStreet  : formData[FieldNames.commercialStreet],
    businessStreet2 : formData[FieldNames.commercialStreet2],
    businessCity    : formData[FieldNames.commercialCity],
    businessState   : formData[FieldNames.commercialState],
    businessZipCode : formData[FieldNames.commercialZip],
    businessPhone   : formData[FieldNames.commercialPhone],
    businessTitle   : formData[FieldNames.commercialBusinessTitle],
    phone           : formData[FieldNames.phone],
    cashAccounts,
    creditCards,
    lifeInsurance,
    loans,
    mortgages,
    notesAndContracts,
    personalProperties,
    realEstate,
    retirementAccounts,
    stocks,
    taxes,
    totalCashAccountValue,
    totalCreditCardValue,
    totalLifeInsuranceValue,
    totalLoansValue,
    totalMortgagesValue,
    totalNotesValue,
    totalPersonalValue,
    totalRealEstateValue,
    totalRetirementValue,
    totalStockValue,
    totalTaxValue,
    hasBankrupt   : formData[FieldNames.decBorrower][FieldNames.hasBankrupt],
    hasForeclosed : formData[FieldNames.decBorrower][FieldNames.hasForeclosed],
    hasJudgements : formData[FieldNames.decBorrower][FieldNames.hasJudgements],
  };
  return financialStatementRequest;
};

const mapCashAccounts = (formData: any): { cashAccounts: FinancialStatementCashAccount[], totalCashAccountValue: number } => {
  const cashAccounts: FinancialStatementCashAccount[] = [];
  let totalCashAccountValue = 0;
  if(hasFSCashAccount(formData)) {
    formData[FieldNames.financialStatementCashAccount].forEach(element => {
      totalCashAccountValue = totalCashAccountValue + element[FieldNames.financialStatementCurrentBalance];
      cashAccounts.push({
        accountType          : element[FieldNames.financialStatementAccountType],
        financialInstitution : element[FieldNames.financialStatementFinancialInstitution],
        jointAccount         : element[FieldNames.financialStatementJointAccount],
        currentBalance       : element[FieldNames.financialStatementCurrentBalance],
      });
    });
  }
  return { cashAccounts, totalCashAccountValue };
};

const mapCreditCards = (formData: any): { creditCards: FinancialStatementCreditCard[], totalCreditCardValue: number } => {
  const creditCards = [];
  let totalCreditCardValue = 0;
  if(hasFSCreditCard(formData)) {
    formData[FieldNames.financialStatementCreditCards].forEach(element => {
      totalCreditCardValue = totalCreditCardValue + element[FieldNames.financialStatementCurrentBalance];
      creditCards.push({
        creditor       : element[FieldNames.financialStatementCreditor],
        currentBalance : element[FieldNames.financialStatementCurrentBalance],
        monthlyPayment : element[FieldNames.financialStatementMonthlyPayment],
        jointAccount   : element[FieldNames.financialStatementJointAccount],
      });
    });
  }
  return { creditCards, totalCreditCardValue };
};

const mapLifeInsurance = (formData: any): { lifeInsurance: FinancialStatementLifeInsurance[], totalLifeInsuranceValue: number } => {
  const lifeInsurance = [];
  let totalLifeInsuranceValue = 0;
  if(hasFSLifeInsurance(formData)) {
    formData[FieldNames.financialStatementLifeInsurance].forEach(element => {
      totalLifeInsuranceValue = totalLifeInsuranceValue + element[FieldNames.financialStatementCashValue];
      lifeInsurance.push({
        company      : element[FieldNames.financialStatementCompany],
        beneficiary  : element[FieldNames.financialStatementBeneficiary],
        faceValue    : element[FieldNames.financialStatementFaceValue],
        cashValue    : element[FieldNames.financialStatementCashValue],
        jointAccount : element[FieldNames.financialStatementJointAccount],
      });
    });
  }
  return { lifeInsurance, totalLifeInsuranceValue };
};

const mapLoans = (formData: any): { loans: FinancialStatementLoansPayable[], totalLoansValue: number } => {
  const loans = [];
  let totalLoansValue = 0;
  if(hasFSLoan(formData)) {
    formData[FieldNames.financialStatementLoans].forEach(element => {
      totalLoansValue = totalLoansValue + element[FieldNames.financialStatementCurrentBalance];
      loans.push({
        creditor       : element[FieldNames.financialStatementCreditor],
        currentBalance : element[FieldNames.financialStatementCurrentBalance],
        description    : element[FieldNames.financialStatementDescription],
        securedBy      : element[FieldNames.financialStatementSecuredBy],
        maturityDate   : element[FieldNames.financialStatementMaturityDate],
        monthlyPayment : element[FieldNames.financialStatementMonthlyPayment],
        jointAccount   : element[FieldNames.financialStatementJointAccount],
      });
    });
  }
  return { loans, totalLoansValue };
};

const mapMortgages = (formData: any): { mortgages: FinancialStatementMortgages[], totalMortgagesValue: number } => {
  const mortgages = [];
  let totalMortgagesValue = 0;
  if(hasFSMortgage(formData)) {
    formData[FieldNames.financialStatementMortgages].forEach(element => {
      totalMortgagesValue = totalMortgagesValue + element[FieldNames.financialStatementCurrentBalance];
      mortgages.push({
        creditor       : element[FieldNames.financialStatementCreditor],
        currentBalance : element[FieldNames.financialStatementCurrentBalance],
        location       : element[FieldNames.financialStatementLocation],
        maturityDate   : element[FieldNames.financialStatementMaturityDate],
        monthlyPayment : element[FieldNames.financialStatementMonthlyPayment],
        jointAccount   : element[FieldNames.financialStatementJointAccount],
      });
    });
  }
  return { mortgages, totalMortgagesValue };
};

const mapNotesAndContracts = (formData: any): { notesAndContracts: FinancialStatementNotesAndContracts[], totalNotesValue: number } => {
  const notesAndContracts = [];
  let totalNotesValue = 0;
  if(hasFSNote(formData)) {
    formData[FieldNames.financialStatementNotes].forEach(element => {
      totalNotesValue = totalNotesValue + element[FieldNames.financialStatementBalanceOwed];
      notesAndContracts.push({
        balanceOwed    : element[FieldNames.financialStatementBalanceOwed],
        maturityDate   : element[FieldNames.financialStatementMaturityDate],
        monthlyPayment : element[FieldNames.financialStatementMonthlyPayment],
        purpose        : element[FieldNames.financialStatementNotePurpose],
        owingParty     : element[FieldNames.financialStatementOwingParty],
        jointAccount   : element[FieldNames.financialStatementJointAccount],
      });
    });
  }
  return { notesAndContracts, totalNotesValue };
};

const mapPersonalProperty = (formData: any): { personalProperties: FinancialStatementPersonalProperty[], totalPersonalValue: number } => {
  const personalProperties = [];
  let totalPersonalValue = 0;
  if(hasFSPersonalProperty(formData)) {
    formData[FieldNames.financialStatementPersonalProperties].forEach(element => {
      totalPersonalValue = totalPersonalValue + element[FieldNames.financialStatementMarketValue];
      personalProperties.push({
        description  : element[FieldNames.financialStatementDescription],
        marketValue  : element[FieldNames.financialStatementMarketValue],
        propertyType : element[FieldNames.financialStatementPersonalType],
        jointAccount : element[FieldNames.financialStatementJointAccount],
      });
    });
  }
  return { personalProperties, totalPersonalValue };
};

const mapRealEstate = (formData: any): { realEstate: FinancialStatementRealEstate[], totalRealEstateValue: number } => {
  const realEstate = [];
  let totalRealEstateValue = 0;
  if(hasFSRealEstate(formData)) {
    formData[FieldNames.financialStatementRealEstate].forEach(element => {
      totalRealEstateValue = totalRealEstateValue + element[FieldNames.financialStatementMarketValue];
      realEstate.push({
        description     : element[FieldNames.financialStatementDescription],
        location        : element[FieldNames.financialStatementLocation],
        marketValue     : element[FieldNames.financialStatementMarketValue],
        purchaseDate    : element[FieldNames.financialStatementPurchaseDate],
        jointAccount    : element[FieldNames.financialStatementJointAccount],
      });
    });
  }
  return { realEstate, totalRealEstateValue };
};

const mapRetirementAccounts = (formData: any): { retirementAccounts: FinancialStatementRetirementAccount[], totalRetirementValue: number } => {
  const retirementAccounts = [];
  let totalRetirementValue = 0;
  if(hasFSRetirementAccount(formData)) {
    formData[FieldNames.financialStatementRetirementAccounts].forEach(element => {
      totalRetirementValue = totalRetirementValue + element[FieldNames.financialStatementMarketValue];
      retirementAccounts.push({
        accountType          : element[FieldNames.financialStatementRetirementType],
        financialInstitution : element[FieldNames.financialStatementFinancialInstitution],
        marketValue          : element[FieldNames.financialStatementMarketValue],
        jointAccount         : element[FieldNames.financialStatementJointAccount],
      });
    });
  }
  return { retirementAccounts, totalRetirementValue };
};

const mapStocks = (formData: any): { stocks: FinancialStatementStock[], totalStockValue: number } => {
  const stocks = [];
  let totalStockValue = 0;
  if(hasFSStock(formData)) {
    formData[FieldNames.financialStatementStocks].forEach(element => {
      totalStockValue = totalStockValue + element[FieldNames.financialStatementMarketValue];
      stocks.push({
        marketValue    : element[FieldNames.financialStatementMarketValue],
        numberOfShares : element[FieldNames.financialStatementNumOfShares],
        securityName   : element[FieldNames.financialStatementSecurityName],
        jointAccount   : element[FieldNames.financialStatementJointAccount],
      });
    });
  }
  return { stocks, totalStockValue };
};

const mapTaxes = (formData: any): { taxes: FinancialStatementTaxesPayable[], totalTaxValue: number } => {
  const taxes = [];
  let totalTaxValue = 0;
  if(hasFSTax(formData)) {
    formData[FieldNames.financialStatementTaxes].forEach(element => {
      totalTaxValue = totalTaxValue + element[FieldNames.financialStatementAmountOwed];
      taxes.push({
        amount       : element[FieldNames.financialStatementAmountOwed],
        description  : element[FieldNames.financialStatementDescription],
        taxType      : element[FieldNames.financialStatementTaxType],
        jointAccount : element[FieldNames.financialStatementJointAccount],
      });
    });
  }
  return { taxes, totalTaxValue };
};
