import { Dispatch } from 'redux';
import { change, destroy, getFormValues, initialize, SubmissionError } from 'redux-form';
import { mapComputedRateValues } from 'app/reducers/rates';
import { getAuditMetadata, makeActionCreator } from 'app/util/actions';
import { postEmailRates } from 'app/api/email';
import { FormName, FormType, LandingLoanType, PartialSource, YesOrNo } from 'app/models/options/enums';
import i18next from 'i18next';
import { routes } from 'app/routes/route-list';
import { push } from 'connected-react-router';
import {
  homeEquityLFInitialValues,
  insuranceQuoteInitialValues,
  purchaseLFInitialValues,
  refinanceLFInitialValues,
  returnInitialValues,
} from 'app/util/initial-values';
import { mapContactUsPayload, postContactUsApplication } from 'app/api/contact-us';
import { toNumber } from 'app/util/parsers';
import { devToolLog, referToLoBypassEnabled } from 'app/actions';
import { roundDownOneThousand } from 'app/util/numbers';
import { isAutoPrequalEnabled, productsSelector } from 'app/reducers/app-config';
import { ResumeParams } from 'app/containers/ResumeSessionContainer';
import { mapLongFormRequest, postLongFormApplication } from 'app/api/loan-application/long-form';
import {
  fetchConsumerLoanLimits,
  fetchConsumerLoanRates,
  fetchRates,
  mapRatesPropertyType,
  mapRatesPropertyUsage,
  mapRatesRequest,
} from 'app/api/rates';
import {
  fetchHomeEquityAcceptedLocation,
  fetchHomeEquityLoanAmountLimits,
  fetchHomeEquityRates,
  mapHomeEquityLoanAmountLimitsRequest,
} from 'app/api/home-equity';
import {
  mapHomeEquityRatesToPayload,
  mapRatesToPayload,
  mapShortFormApplication,
  mapUserDataToPayload,
  postShortForm,
} from 'app/api/short-form';
import { mapChecklistRequest, postChecklist } from 'app/api/checklist';
import { getPartialApp, mapPartialData, postPartialApp, putPartialApp } from 'app/api/partial-app';
import { getLocale } from 'app/api/helpers';
import {
  getPrequalLetter,
  isLetterExpired,
  mapPrequalLetterRequest,
  mapPrequalLetterResponse,
  postPrequalLetter,
} from 'app/api/prequal-letter';
import { Type } from './types';
import { FieldNames } from 'app/models/fields/names';
import { hasPrequalAmount, hasRate, isFromDealer, noChosenHome } from 'app/models/fields/conditionals';
import { PrequalRateInfo } from 'app/models/types';
import {
  AUTO_LOAN_APP_COBORROWER_PERSONAL_INFORMATION,
  AUTO_LOAN_APP_FINAL,
  AUTO_LOAN_CREDIT_LIFE_QUOTE,
  AUTO_LOAN_GET_LOAN_INFORMATION,
  CLOSING_COSTS_COMPLETION,
  CLOSING_COSTS_RATE_SELECTION,
  HOME_EQUITY_COBORROWER_PERSONAL_INFORMATION,
  LF_HELOC_MINIMUM_LOAN_STOP_GATE,
  LF_HELOC_STATE_NOT_COVERED_STOP_GATE,
  PERSONAL_LOAN_APP_CO_BORROWER_DATA,
  PERSONAL_LOAN_APP_FINAL_SECTION,
  PERSONAL_LOAN_GET_LOAN_AMOUNT_SECTION,
  SF_HOME_EQUITY_MIN_LOAN_STOP_GATE,
  SUMMARY_SECTION,
} from 'app/models/sections/constants';
import { NotificationMessage } from 'app/reducers/notifications';
import { getSlug } from 'app/util/headers';
import {
  ConsumerLoanLimitsResponse,
  ConsumerLoanRequestType,
  LoanProgramRatesResponse,
  LoanPurpose,
} from 'app/api/rates/types';
import { logger } from 'app/util/logger';
import { getLoanOfficer } from 'app/reducers/loan-officer';
import { hasEitherSsn } from 'app/components/FormFields/Ssn/CollectSsnBase';
import { getFormName, getRouteByFormName } from 'app/routes/helpers';
import { mapUniversalLoanRequest, postUniversalLoanApplication } from 'app/api/loan-application/universal/loan';
import { LoanOfficerDetail } from 'app/api/loan-officer';
import { AuditUserEventRequest, AuditUserEventType, postAuditUserEvent } from 'app/api/audit-event';
import { Binary, GeneralReferralCode, Language, Product, ReferralCode, UniversalLoanSource } from '@lenderful/domain';
import { splitDateAndTime } from "../../util/dates";
import { ShortFormRequestBody } from 'app/api/short-form/types';
import { mapFinancialStatement } from '../../api/financial-statement/mappings';
import { HomeEquityRatesResponse } from 'app/api/home-equity/types';
import { selectAVMReportId } from 'app/reducers/report';
import { handleCommercialMapping } from '../../api/loan-application/universal/mappings';

/**
 * BASIC ACTIONS
 *
 * These actions are basic actions without a payload, or with a payload
 * and handled by the reducer
 */

export const ajaxContactUsFail                   = makeActionCreator(Type.AJAX_CONTACT_US_FAIL, 'payload');
export const ajaxContactUsSuccess                = makeActionCreator(Type.AJAX_CONTACT_US_SUCCESS, 'payload');
export const ajaxEmailRatesFail                  = makeActionCreator(Type.AJAX_EMAIL_RATES_FAIL, 'payload');
export const ajaxEmailRatesSuccess               = makeActionCreator(Type.AJAX_EMAIL_RATES_SUCCESS, 'payload');
export const ajaxFetchAutoLoanRatesFail          = makeActionCreator(Type.AJAX_FETCH_AUTO_LOAN_RATES_FAIL, 'payload');
export const ajaxFetchAutoLoanRatesSuccess       = makeActionCreator(Type.AJAX_FETCH_AUTO_LOAN_RATES_SUCCESS, 'payload');
export const ajaxFetchRatesFail                  = makeActionCreator(Type.AJAX_FETCH_RATES_FAIL, 'payload');
export const ajaxFetchRatesSuccess               = makeActionCreator(Type.AJAX_FETCH_RATES_SUCCESS, 'payload');
export const ajaxFetchHomeEquityRatesFail        = makeActionCreator(Type.AJAX_FETCH_HOME_EQUITY_RATES_FAIL, 'payload');
export const ajaxFetchHomeEquityRatesSuccess     = makeActionCreator(Type.AJAX_FETCH_HOME_EQUITY_RATES_SUCCESS, 'payload');
export const ajaxFetchPersonalLoanRatesFail      = makeActionCreator(Type.AJAX_FETCH_PERSONAL_LOAN_RATES_FAIL, 'payload');
export const ajaxFetchPersonalLoanRatesSuccess   = makeActionCreator(Type.AJAX_FETCH_PERSONAL_LOAN_RATES_SUCCESS, 'payload');
export const ajaxFetchConsumerLoanLimitsFail     = makeActionCreator(Type.AJAX_FETCH_CONSUMER_LOAN_LIMITS_FAIL, 'payload');
export const ajaxFetchConsumerLoanLimitsSuccess  = makeActionCreator(Type.AJAX_FETCH_CONSUMER_LOAN_LIMITS_SUCCESS, 'payload');
export const ajaxGetPartialAppFail               = makeActionCreator(Type.AJAX_GET_PARTIAL_APP_FAIL, 'payload');
export const ajaxGetPartialAppSuccess            = makeActionCreator(Type.AJAX_GET_PARTIAL_APP_SUCCESS, 'payload');
export const ajaxGetHomeEquityPrefillSuccess     = makeActionCreator(Type.AJAX_GET_HOME_EQUITY_PREFILL_SUCCESS, 'payload');
export const ajaxGetHomeEquityPrefillFail        = makeActionCreator(Type.AJAX_GET_HOME_EQUITY_PREFILL_FAIL, 'payload');
export const ajaxGetPrequalLetterFail            = makeActionCreator(Type.AJAX_GET_PREQUAL_LETTER_FAIL, 'payload');
export const ajaxGetPrequalLetterSuccess         = makeActionCreator(Type.AJAX_GET_PREQUAL_LETTER_SUCCESS, 'payload');
export const ajaxGetPrequalRatesFail             = makeActionCreator(Type.AJAX_GET_PREQUAL_RATES_FAIL, 'payload');
export const ajaxGetPrequalRatesSuccess          = makeActionCreator(Type.AJAX_GET_PREQUAL_RATES_SUCCESS, 'payload');
export const ajaxGetSbRatesFail                  = makeActionCreator(Type.AJAX_GET_SB_RATES_FAIL, 'payload');
export const ajaxGetSbRatesSuccess               = makeActionCreator(Type.AJAX_GET_SB_RATES_SUCCESS, 'payload');
export const ajaxPostChecklistFail               = makeActionCreator(Type.AJAX_POST_CHECKLIST_FAIL, 'payload');
export const ajaxPostChecklistSuccess            = makeActionCreator(Type.AJAX_POST_CHECKLIST_SUCCESS, 'payload');
export const ajaxPostMortgageAppFail             = makeActionCreator(Type.AJAX_POST_MORTGAGE_APP_FAIL, 'payload');
export const ajaxPostMortgageAppSuccess          = makeActionCreator(Type.AJAX_POST_MORTGAGE_APP_SUCCESS, 'payload');
export const ajaxPostPartialAppFail              = makeActionCreator(Type.AJAX_POST_PARTIAL_APP_FAIL, 'payload');
export const ajaxPostPartialAppSuccess           = makeActionCreator(Type.AJAX_POST_PARTIAL_APP_SUCCESS, 'payload');
export const ajaxPostPrequalLetterFail           = makeActionCreator(Type.AJAX_POST_PREQUAL_LETTER_FAIL, 'payload');
export const ajaxPostPrequalLetterSuccess        = makeActionCreator(Type.AJAX_POST_PREQUAL_LETTER_SUCCESS, 'payload');
export const ajaxpostTurboFail                   = makeActionCreator(Type.AJAX_POST_HOME_EQUITY_TURBO_FAIL, 'payload');
export const ajaxpostTurboSuccess                = makeActionCreator(Type.AJAX_POST_HOME_EQUITY_TURBO_SUCCESS, 'payload');
export const ajaxPostPurchaseTurboFail          = makeActionCreator(Type.AJAX_POST_PURCHASE_TURBO_FAIL, 'payload');
export const ajaxPostPurchaseTurboSuccess       = makeActionCreator(Type.AJAX_POST_PURCHASE_TURBO_SUCCESS, 'payload');
export const ajaxPostRefinanceTurboFail          = makeActionCreator(Type.AJAX_POST_REFINANCE_TURBO_FAIL, 'payload');
export const ajaxPostRefinanceTurboSuccess       = makeActionCreator(Type.AJAX_POST_REFINANCE_TURBO_SUCCESS, 'payload');
export const ajaxPostUserDataFail                = makeActionCreator(Type.AJAX_POST_USER_DATA_FAIL, 'payload');
export const ajaxPostUserDataSuccess             = makeActionCreator(Type.AJAX_POST_USER_DATA_SUCCESS, 'payload');
export const ajaxPutPartialAppFail               = makeActionCreator(Type.AJAX_PUT_PARTIAL_APP_FAIL, 'payload');
export const ajaxPutPartialAppSuccess            = makeActionCreator(Type.AJAX_PUT_PARTIAL_APP_SUCCESS, 'payload');
export const getNextSection                      = makeActionCreator(Type.GET_NEXT_SECTION, 'formValues', 'conditionalIf');
export const getPreviousSection                  = makeActionCreator(Type.GET_PREVIOUS_SECTION);
export const goToSection                         = makeActionCreator(Type.GO_TO_SECTION, 'id');
export const modalPartialClose                   = makeActionCreator(Type.MODAL_PARTIAL_CLOSE);
export const setSubmittedData                    = makeActionCreator(Type.SET_SUBMITTED_DATA, 'formName', 'payload');

/**
 * SYNC ACTIONS
 *
 * These actions are straight forward and do not interact with the server
 */
export function setSelectedRate(payload) {
  return (dispatch, getState) => {
    dispatch({ type: Type.SET_SELECTED_RATE, payload });
    const formStore = getState().form; // activeForms
    if (formStore) {
      const { apr, rate, quoteFees, term, productFamily, productType, principalAndInterestPayment } = payload;
      Object.keys(formStore).forEach((formName) => {
        /* Set rate values to each active form */
        dispatch(change(formName, FieldNames.rate, rate));
        dispatch(change(formName, FieldNames.fee, quoteFees));
        dispatch(change(formName, FieldNames.apr, apr));
        dispatch(change(formName, FieldNames.loanTerm,  toNumber(term)));
        dispatch(change(formName, FieldNames.loanType, productFamily));
        dispatch(change(formName, FieldNames.productType, productType));
        dispatch(change(formName, FieldNames.monthlyPayment, principalAndInterestPayment));
        /* Set entire rate row to the selectedRate field */
        dispatch(change(formName, FieldNames.selectedRate, payload));
      });
    } else {
      logger.error('Could not set selected rate in setSelectedRate');
    }
  };
}

export function resetAllForms() {
  return (dispatch, getState) => {
    dispatch({ type: 'RESET_ALL_FORMS ' });
    dispatch(destroy(FormName.CONTACT));
    dispatch(destroy(FormName.HOMEEQUITY));
    dispatch(destroy(FormName.HOMEEQUITYLONGFORM));
    dispatch(destroy(FormName.LONGFORM));
    dispatch(destroy(FormName.SHORT_FORM_PURCHASE));
    dispatch(destroy(FormName.SHORT_FORM_REFINANCE));
    dispatch(destroy(FormName.PERSONALLOAN));
  };
}

export function setInitialFormValues(payload) {
  return (dispatch, getState) => {
    const { formName, formData } = payload;
    dispatch({ type: Type.SET_FORM_INITIAL_VALUES, payload });
    dispatch(initialize(formName, formData, false));
  };
}

export function goToLongForm(payload) {
  return (dispatch, getState) => {
    dispatch({ type: Type.GO_TO_LONG_FORM, payload });
    /* Check short form type to determine which long form to navigate to */
    if (payload === FormType.PURCHASE) {
      dispatch(push(routes.applicationPurchase));
    } else {
      dispatch(push(routes.applicationRefinance));
    }
  };
}

export function goToClosingCostsForm(formName: FormName) {
  return (dispatch, getState) => {
    const currentFormData = getFormValues(formName)(getState());
    const initialValueDetails   = {
      formName: FormName.CLOSING_COSTS,
      formData: currentFormData,
    };
    return Promise.resolve(dispatch(setInitialFormValues(initialValueDetails)))
      .then(() => {
        if (currentFormData[FieldNames.loanPurpose] === LoanPurpose.PURCHASE) {
          dispatch(push(routes.closingCostsPurchase));
        } else {
          dispatch(push(routes.closingCostsRefinance));
        }
      });
  };
}

export function goToInsuranceQuoteForm(formName: FormName) {
  return async (dispatch, getState) => {
    const currentFormData = getFormValues(formName)(getState());
    const initialValueDetails   = {
      formName: FormName.INSURANCE_QUOTE,
      formData: { ...currentFormData, ...insuranceQuoteInitialValues },
    };
    await Promise.resolve(dispatch(setInitialFormValues(initialValueDetails)));
    return dispatch(push(routes.insuranceQuote));
  };
}

export function handleClosingCostsRateLookup(payload) {
  return (dispatch, getState) => {
    const closingCostsData = getFormValues(FormName.CLOSING_COSTS)(getState());
    if (hasRate(closingCostsData)) {
      dispatch(goToSection(CLOSING_COSTS_COMPLETION));
    } else {
      return Promise.resolve(dispatch(ajaxFetchRates(payload)))
        .then(() => dispatch(goToSection(CLOSING_COSTS_RATE_SELECTION)));
    }
  };
}

export function handleClosingCostsRateSelect() {
  return (dispatch) => dispatch(goToSection(CLOSING_COSTS_COMPLETION));
}

/**
 * Determines the next transition from the long form.
 * 1. Borrower is on pre-qualification track
 *    & client config has auto prequal enabled -> auto-prequal
 * 2. Borrower is on pre-qualification track
 *    & client config has auto prequal disabled -> regular pre-qual long form
 * 3. Borrower is not on pre-qualification track -> regular purchase/refi long form
 */
export function determinePrequalTransition(payload) {
  return (dispatch, getState) => {
    dispatch({ type: Type.DETERMINE_PREQUAL_TRANSITION, payload });
    const state              = getState();
    const formData         = getFormValues(FormName.LONGFORM)(state);
    const autoPrequalEnabled = isAutoPrequalEnabled(state);
    const isOnPrequalTrack   = (noChosenHome(formData) && hasPrequalAmount(formData));

    if (isOnPrequalTrack && autoPrequalEnabled) {
      logger.debug('Redirecting to auto-prequalification');
      dispatch(setInitialFormValues({ formName: FormName.AUTOPREQUAL, formData }));
      /* Navigate user to auto-prequal form section 2 */
      dispatch(push(routes.autoPrequal + '#102'));
    } else {
      logger.debug('Borrower not being redirected to auto-prequal: ', { isOnPrequalTrack, autoPrequalEnabled });
      /* Dispatch getNextSection */
      dispatch(getNextSection());
    }
  };
}

export function handleShortFormRateSelect(payload) {
  return (dispatch, getState) => {
    const { isPurchase } = payload;
    dispatch({ type: Type.HANDLE_SHORTFORM_RATE_SELECT, payload });
    /* Determine if pre-qual or refinance */
    const formType      = isPurchase ? FormType.PURCHASE : FormType.REFINANCE;
    const formName      = isPurchase ? FormName.SHORT_FORM_PURCHASE : FormName.SHORT_FORM_REFINANCE;
    const shortFormData = getFormValues(formName)(getState());

    /* Get the initial values for the long form  */
    const longFormDefaultValues = isPurchase ? purchaseLFInitialValues : refinanceLFInitialValues;
    const initialValueDetails   = {
      formName: FormName.LONGFORM,
      formData: Object.assign({}, longFormDefaultValues, shortFormData),
    };
      /* Initialize long form initial values */
    dispatch(setInitialFormValues(initialValueDetails));
    /* Navigate to long form */
    dispatch(goToLongForm(formType));
  };
}

export function handleHomeEquityRateSelect() {
  return (dispatch, getState) => {
    const homeEquityShortFormData = getFormValues(FormName.HOMEEQUITY)(getState());
    /* Get the initial values for the long form  */
    const initialValueDetails = {
      formName: FormName.HOMEEQUITYLONGFORM,
      formData: Object.assign({}, homeEquityLFInitialValues, homeEquityShortFormData),
    };
    /* Initialize long form initial values */
    dispatch(setInitialFormValues(initialValueDetails));
    /* Navigate to long form */
    dispatch(push(`${routes.applicationHomeEquity}#${HOME_EQUITY_COBORROWER_PERSONAL_INFORMATION}`));
  };
}

export function handlePersonalLoanRateSelect() {
  return (dispatch, getState) => {
    dispatch(push(`${routes.personalLoan}#${PERSONAL_LOAN_APP_CO_BORROWER_DATA}`));
  };
}

export function handleAutoLoanRateSelect() {
  return (dispatch) => {
    dispatch(push(`${routes.autoLoan}#${AUTO_LOAN_APP_COBORROWER_PERSONAL_INFORMATION}`));
  };
}

export function setPrequalRate(prequalResponse: PrequalRateInfo[] = []) {
  return (dispatch, getState) => {
    dispatch({ type: Type.SET_PREQUAL_RATE, prequalResponse });
    const [prequalRate] = prequalResponse;
    /* Set form values */
    if (prequalRate) {
      const roundedPrequalAmount = roundDownOneThousand(prequalRate.prequalAmount);
      const roundedLoanAmount    = roundDownOneThousand(prequalRate.loanAmount);
      const downPercent          = (prequalRate.downPayment / roundedPrequalAmount) * 100;
      const roundedPercent       = Math.floor(downPercent * 10) / 10;

      dispatch(setSelectedRate(prequalRate));
      dispatch(change(FormName.AUTOPREQUAL, FieldNames.loanAmount, roundedLoanAmount));
      dispatch(change(FormName.AUTOPREQUAL, FieldNames.downDollar, prequalRate.downPayment));
      dispatch(change(FormName.AUTOPREQUAL, FieldNames.downPercent, roundedPercent));
      dispatch(change(FormName.AUTOPREQUAL, FieldNames.prequalAmount, roundedPrequalAmount));
      dispatch(change(FormName.AUTOPREQUAL, FieldNames.prequalLetterAmount, roundedPrequalAmount));
      dispatch(change(FormName.AUTOPREQUAL, FieldNames.prequalIssued, splitDateAndTime(prequalRate.prequalIssued.toString())));
      dispatch(change(FormName.AUTOPREQUAL, FieldNames.prequalExpiration, splitDateAndTime(prequalRate.prequalExpiration.toString())));
    } else {
      logger.debug('Could not pre-qualify borrower, referring to LO');
      dispatch(referToLoanOfficer(GeneralReferralCode.INSUFFICIENT_MONTHLY_PAYMENT));
    }
  };
}

export function setMaxLoanAmount(payload) {
  return (dispatch, getState) => {
    const formName = getFormName(getState().router.location.pathname);
    dispatch(change(formName, FieldNames.maxLoanAmount, payload));
  };
}

export function setMinLoanAmount(payload) {
  return (dispatch, getState) => {
    const formName = getFormName(getState().router.location.pathname);
    dispatch(change(formName, FieldNames.minLoanAmount, payload));
  };
}

export function referToLoanOfficer(code: ReferralCode) {
  return (dispatch, getState) => {
    logger.debug(`%c Refer To Loan Officer: ${code}`, 'color: red');
    /* If the development tool for bypassing referrals is enabled, get next section */
    if (referToLoBypassEnabled()) {
      devToolLog('Bypassing loan officer referral');
      dispatch(getNextSection());
    } else {
      dispatch({ type: Type.REFER_TO_LOAN_OFFICER, code });
    }
  };
}

/**
 * Adds a notification to the notification reducer.
 * This action written as a function literal instead of using the
 * makeActionCreator helper to preserve type safety
 */
export function pushNotification(notification: PartialPick<NotificationMessage, 'type'>) {
  return (dispatch) => {
    const payload: NotificationMessage = {
      /* Default values */
      timestamp  : new Date(),
      i18nKey    : 'default',
      defaultText: notification.type === 'success' ? 'Success' : 'An error has occured',
      /* Notification values */
      ...notification,
    };
    dispatch({ type: Type.PUSH_NOTIFICATION, payload });
  };
}

/**
 * Updates a notification by index. The reducer will apply all properties
 * passed into this action except for the index.
 */
export function updateNotification(payload: { index: number, hasShown: boolean }) {
  return (dispatch) => {
    dispatch({ type: Type.UPDATE_NOTIFICATION, payload });
  };
}

/**
 * Helper function for calling the pushNotification action with pre-defined
 * messaging for a route that has been disabled from the routeToggles
 */
export function pushRouteDisabledNotification() {
  return (dispatch) => {
    dispatch(pushNotification({
      type       : 'error',
      i18nKey    : 'disabledRoute',
      defaultText: 'This route is currently disabled',
    }));
  };
}
/**
 * ASYNC/THUNK ACTIONS
 *
 * These actions handle async requests like hitting the API for data
 */
export function handleShortFormSubmit(payload) {
  return (dispatch, getState) => {
    dispatch({ type: Type.HANDLE_SHORTFORM_SUBMIT, payload });
    return Promise.resolve(
      dispatch(ajaxFetchRates(payload)),
    ).then(() => {
      return Promise.resolve(
        dispatch(ajaxPostShortForm()),
      ).then((response) => {
        const state = getState();
        const formName = getFormName(state.router.location.pathname);
        payload.insertId = response;
        dispatch(setSubmittedData(formName, payload));
      }).then(() => {
        dispatch(goToSection(SUMMARY_SECTION));
      });
    });
  };
}

export function handlePersonalLoanFetchLimits(payload) {
  return (dispatch) => {
    return Promise.resolve(dispatch(ajaxFetchConsumerLoanLimits(payload, ConsumerLoanRequestType.PERSONAL)))
      .then((response: ConsumerLoanLimitsResponse) => {
        dispatch(setMaxLoanAmount(response.maxLoanAmount));
        dispatch(setMinLoanAmount(response.minLoanAmount));
        return dispatch(goToSection(PERSONAL_LOAN_GET_LOAN_AMOUNT_SECTION));
      });
  };
}

export function handleAutoLoanFetchLimits(payload) {
  return (dispatch) => {
    return Promise.resolve(dispatch(ajaxFetchConsumerLoanLimits(payload, ConsumerLoanRequestType.AUTO)))
      .then((response: ConsumerLoanLimitsResponse) => {
        dispatch(setMaxLoanAmount(response.maxLoanAmount));
        dispatch(setMinLoanAmount(response.minLoanAmount));
        return dispatch(goToSection(AUTO_LOAN_GET_LOAN_INFORMATION));
      });
  };
}

export function handlePersonalLoanFetchRates(payload) {
  return (dispatch) => {
    return Promise.resolve(dispatch(ajaxFetchPersonalLoanRates(payload)))
      .then((programs: LoanProgramRatesResponse) => {
        if (!programs || programs.length === 0) {
          // handle 200 response with no rates
          logger.warn('handlePersonalLoanFetchRates: No qualified rates returned', programs);
          return dispatch(referToLoanOfficer(GeneralReferralCode.NO_QUALIFIED_PREQUAL_RATES));
        }
        return dispatch(goToSection(SUMMARY_SECTION));
      });
  };
}

export function handleAutoLoanFetchRates(payload) {
  return async (dispatch) => {
    const isFromDealerValue = isFromDealer(payload);
    dispatch(change(FormName.AUTOLOAN, FieldNames.isFromDealer, isFromDealerValue));

    const tradeInValue = isFromDealerValue ? (payload[FieldNames.tradeInValue] || 0) : 0;
    dispatch(change(FormName.AUTOLOAN, FieldNames.tradeInValue, tradeInValue));

    const finalLoanAmount = payload[FieldNames.purchasePrice] - (tradeInValue + payload[FieldNames.downDollar]);
    dispatch(change(FormName.AUTOLOAN, FieldNames.loanAmount, finalLoanAmount));

    payload = { ...payload, loanAmount : finalLoanAmount };

    const programs = await Promise.resolve(dispatch(ajaxFetchAutoLoanRates(payload)));
    if (!programs || programs.length === 0) {
      // handle 200 response with no rates
      logger.warn('handleAutoLoanFetchRates: No qualified rates returned', programs);
      return dispatch(referToLoanOfficer(GeneralReferralCode.NO_QUALIFIED_PREQUAL_RATES));
    }
    return dispatch(goToSection(SUMMARY_SECTION));
  };
}

export function handleLoanAmountUpdate(loanAmount) {
  return (dispatch) => {
    dispatch(change(FormName.PERSONALLOAN, FieldNames.loanAmount, loanAmount));
  };
}

export function handleAutoLoanDetailsUpdate(loanTermDesired, creditScore, purchasePrice, tradeInValue, downPayment) {
  return (dispatch) => {
    dispatch(change(FormName.AUTOLOAN, FieldNames.loanTermDesired, loanTermDesired));
    dispatch(change(FormName.AUTOLOAN, FieldNames.creditScore, creditScore));
    dispatch(change(FormName.AUTOLOAN, FieldNames.purchasePrice, purchasePrice));
    dispatch(change(FormName.AUTOLOAN, FieldNames.tradeInValue, tradeInValue));
    dispatch(change(FormName.AUTOLOAN, FieldNames.downDollar, downPayment));
  };
}

export function handleLongFormTypeSelect(payload) {
  return (dispatch, getState) => {
    dispatch({ type: Type.HANDLE_LONGFORM_TYPE_SELECT, payload });

    const loanType = payload[FieldNames.landingLoanType];
    const initialValues = payload[FieldNames.landingLoanType] === LandingLoanType.PURCHASE ?
      purchaseLFInitialValues :
      refinanceLFInitialValues;
    const currentFormData = getFormValues(FormName.LONGFORM)(getState());
    const initialValueDetails   = {
      formName: FormName.LONGFORM,
      formData: { ...initialValues, ...currentFormData },
    };
    dispatch(setInitialFormValues(initialValueDetails));

    /* Determine if user has selected purchase or refinance from the application landing */
    const redirectRoute = loanType === LandingLoanType.PURCHASE ? routes.applicationPurchase : routes.applicationRefinance;
    dispatch(push(redirectRoute));
  };
}

export function ajaxGetPrequalRates(payload, enableNextSection: boolean, referToLo?) {
  return (dispatch, getState) => {
    dispatch({ type: Type.AJAX_GET_PREQUAL_RATES, payload });

    return dispatch(ajaxFetchRates(payload, true))
      .then((response) => {
        if (response.length === 0) { // handle 200 response with no rates
          logger.warn('ajaxGetPrequalRates: No qualified rates returned', response);
          dispatch(referToLoanOfficer(GeneralReferralCode.NO_QUALIFIED_PREQUAL_RATES));
        } else { // handle 200 response with rates
          dispatch(setPrequalRate(response));
          dispatch(ajaxGetPrequalRatesSuccess());
          if(enableNextSection) {
            // Then send the user to the next section
            dispatch(getNextSection(payload, referToLo));
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 408) { // handle timeout, the rates API is down
          logger.error('ajaxGetPrequalRates: Rates timed out', error);
          dispatch(referToLoanOfficer(GeneralReferralCode.RATES_TIMEOUT));
        } else { // handle unknown error by logging
          logger.error('ajaxGetPrequalRates: Unknown error', error);
          dispatch(referToLoanOfficer(GeneralReferralCode.NO_QUALIFIED_PREQUAL_RATES));
        }
      });
  };
}

export function handleHomeEquityLongFormZipCheck(payload) {
  return async (dispatch) => {

    let isLocationValid = false;
    try {
      const locationResponse = await fetchHomeEquityAcceptedLocation({ propertyZip: payload[FieldNames.propertyZip] });
      isLocationValid = locationResponse.isValid;
    } catch (error) {
      const errorMessage = error.response.data?.error?.description ?? 'Invalid Zip';
      const errors = { [FieldNames.propertyZip] : errorMessage };
      throw new SubmissionError(errors);
    }

    dispatch({ type: Type.HANDLE_HOMEEQUITY_SUBMIT_FIRST, payload });
    return Promise.all([
      dispatch(setSubmittedData(FormName.HOMEEQUITY, payload)),
    ]).then(() => {
      if (isLocationValid) {
        // If LocationValid, go to next section
        return dispatch((getNextSection()));
      } else {
        // If false(location NOT valid), go to the stop gate
        return dispatch(goToSection(LF_HELOC_STATE_NOT_COVERED_STOP_GATE));
      }
    });
  };
}

export function handleHomeEquityLongFormZipCheckDisplayError(payload) {
  return async (dispatch) => {

    let isLocationValid = false;
    try {
      const locationResponse = await fetchHomeEquityAcceptedLocation({ propertyZip: payload[FieldNames.propertyZip] });
      isLocationValid = locationResponse.isValid;
    } catch (error) {
      const errorMessage = error.response.data?.error?.description ?? 'Invalid Zip';
      const errors = { [FieldNames.propertyZip] : errorMessage };
      throw new SubmissionError(errors);
    }

    dispatch({ type: Type.HANDLE_HOMEEQUITY_SUBMIT_FIRST, payload });
    return Promise.all([
      dispatch(setSubmittedData(FormName.HOMEEQUITY, payload)),
    ]).then(() => {
      if (isLocationValid) {
        return dispatch((getNextSection()));
      } else {
        throw new SubmissionError({ [FieldNames.propertyZip] : 'We do not write loans in this zip code.' });
      }
    });
  };
}

export function handleHomeEquitySubmitFirst(payload) {
  return async (dispatch, getState) => {
    const state = getState();
    const formName = getFormName(state.router.location.pathname);

    const loanAmountLimitsRequest = mapHomeEquityLoanAmountLimitsRequest(payload);
    const { maxLoanAmount, maxLTV, minLoanAmount } = await fetchHomeEquityLoanAmountLimits(loanAmountLimitsRequest);

    // Set the max loan amount in payload because we set submitted data below
    payload[FieldNames.maxLoanAmount] = maxLoanAmount;
    payload[FieldNames.minLoanAmount] = minLoanAmount;
    payload[FieldNames.maxLtvPercent] = maxLTV;
    dispatch(change(formName, FieldNames.maxLoanAmount, maxLoanAmount));
    dispatch(change(formName, FieldNames.minLoanAmount, minLoanAmount));
    dispatch(change(formName, FieldNames.maxLtvPercent, maxLTV));

    dispatch({ type: Type.HANDLE_HOMEEQUITY_SUBMIT_FIRST, payload });
    return Promise.all([
      dispatch(setSubmittedData(FormName.HOMEEQUITY, payload)),
    ]).then(() => {
      if (maxLoanAmount >= minLoanAmount) {
        return dispatch(goToSection(SUMMARY_SECTION));
      } else {
        return dispatch(goToSection(SF_HOME_EQUITY_MIN_LOAN_STOP_GATE));
      }
    });
  };
}

export function handleHomeEquitySubmitSecond(payload) {
  return (dispatch, getState) => {
    dispatch({ type: Type.HANDLE_HOMEEQUITY_SUBMIT_SECOND, payload });
    return Promise.resolve(
      dispatch(ajaxFetchHomeEquityRates(payload)),
    ).then((homeEquityRatesResponse: HomeEquityRatesResponse) => {
      return Promise.resolve(
        dispatch(ajaxPostShortForm()),
      ).then((response) => {
        payload.insertId = response;
        dispatch(setSubmittedData(FormName.HOMEEQUITY, payload));
      }).then(() => {
        // Check if the rates returned
        if (homeEquityRatesResponse.fixedRatePrograms.length > 0 || homeEquityRatesResponse.lineOfCredit) {
          // If true, go to the rate summary
          return dispatch(goToSection(SUMMARY_SECTION));
        } else {
          // If false, go to the refer to LO stop gate
          return dispatch(referToLoanOfficer(GeneralReferralCode.NO_QUALIFIED_HELOC_RATES));
        }
      });
    });
  };
}

export function handleHomeEquityRecalculateRates(payload) {
  return (dispatch) => {
    dispatch({ type: Type.HANDLE_RECALCULATE_RATES, payload });
    return dispatch(ajaxFetchHomeEquityRates(payload)).then(() => {
      dispatch(setSubmittedData(FormName.HOMEEQUITY, payload));
      // Scroll back to top
      window.scrollTo(0, 0);
    });
  };
}

export function handleRecalculateRates(payload) {
  return (dispatch, getState) => {
    dispatch({ type: Type.HANDLE_RECALCULATE_RATES, payload });
    return dispatch(ajaxFetchRates(payload)).then(() => {
      const state = getState();
      const formName = getFormName(state.router.location.pathname);
      dispatch(setSubmittedData(formName, payload));
      // Scroll back to top
      window.scrollTo(0, 0);
    });
  };
}

export function handleHomeEquityRecalculateMaxLoan(payload) {
  return async (dispatch, getState) => {
    const state = getState();
    const formName = getFormName(state.router.location.pathname);

    dispatch({ type: Type.HANDLE_RECALCULATE_RATES, payload });

    const loanAmountLimitsRequest = mapHomeEquityLoanAmountLimitsRequest(payload);
    const { maxLoanAmount, maxLTV } = await fetchHomeEquityLoanAmountLimits(loanAmountLimitsRequest);

    payload[FieldNames.maxLoanAmount] = maxLoanAmount;
    payload[FieldNames.maxLtvPercent] = maxLTV;
    dispatch(change(formName, FieldNames.maxLoanAmount, maxLoanAmount));
    dispatch(change(formName, FieldNames.maxLtvPercent, maxLTV));

    dispatch(setSubmittedData(FormName.HOMEEQUITY, payload));
    return (
      // Scroll back to top
      window.scrollTo(0, 0)
    );
  };
}

export function creditCardRecordAuditEventAndRedirect(values) {
  return async (dispatch) => {
    const request: AuditUserEventRequest = {
      auditEvent: {
        email      : values.email,
        firstName  : values.firstName,
        middleName : values.middleName,
        lastName   : values.lastName,
        eventMeta  : JSON.stringify(getAuditMetadata('Next')),
        eventType  : AuditUserEventType.USER_ACKNOWLEDGES_CC_DISCLOSURE,
      },
    };
    await postAuditUserEvent(request);
    dispatch(getNextSection());
  };
}

export function autoRecordAuditEventAndRedirect(payload) {
  return async (dispatch, getState) => {
    const state = getState();
    const hasAutoInsuranceQuoteProduct = productsSelector(state).some(product => product.name === Product.AUTO_INSURANCE_QUOTE);

    const request: AuditUserEventRequest = {
      auditEvent: {
        email      : payload.email,
        firstName  : payload.firstName,
        middleName : payload.middleName,
        lastName   : payload.lastName,
        eventMeta  : JSON.stringify(getAuditMetadata('Continue')),
        eventType  : AuditUserEventType.USER_ACCEPTS_PREQUAL_READ,
      },
    };
    await postAuditUserEvent(request);
    if (hasAutoInsuranceQuoteProduct) {
      dispatch(push(`${routes.autoLoan}#${AUTO_LOAN_CREDIT_LIFE_QUOTE}`));
    } else {
      dispatch(push(`${routes.autoLoan}#${AUTO_LOAN_APP_FINAL}`));
    }
  };
}

export function personalRecordAuditEventAndRedirect(payload) {
  return async (dispatch) => {
    const request: AuditUserEventRequest = {
      auditEvent: {
        email      : payload.email,
        firstName  : payload.firstName,
        middleName : payload.middleName,
        lastName   : payload.lastName,
        eventMeta  : JSON.stringify(getAuditMetadata('Continue')),
        eventType  : AuditUserEventType.USER_ACCEPTS_PREQUAL_READ,
      },
    };
    await postAuditUserEvent(request);
    dispatch(push(`${routes.personalLoan}#${PERSONAL_LOAN_APP_FINAL_SECTION}`));
  };
}

export function ajaxFetchRates(formData, isPrequal = false) {
  // Redux Thunk will inject dispatch here:
  return (dispatch: Dispatch, getState) => {
    const buildPayload = () => {
      if (isPrequal) {
        return { isPrequal: true, ...mapRatesRequest(formData, getState().config, isPrequal) };
      } else {
        return mapRatesRequest(formData, getState().config);
      }
    };
    const payload = buildPayload();
    // Dispatch the action we're calling in case we want the reducer to do anything
    dispatch({ type: Type.AJAX_FETCH_RATES, payload });
    // Perform the actual API call
    return fetchRates(payload).then(
      (response) => {
        logger.debug('ajaxFetchRates response: ', response);
        response.forEach((rate) => {
          mapComputedRateValues(rate, isPrequal, formData[FieldNames.loanPurpose]);
        });
        dispatch(ajaxFetchRatesSuccess(response));
        return response;
      },
      (error) => {
        dispatch(ajaxFetchRatesFail(error));
        throw error;
      },
    );
  };
}

/**
 * This method builds the request to get home equity rates and handles
 * the response.
 *
 * @param formData
 * @returns
 */
export function ajaxFetchHomeEquityRates(formData) {
  return (dispatch: Dispatch) => {
    const request = {
      creditReportId             : formData[FieldNames.creditReportId],
      fico                       : formData[FieldNames.creditScore],
      hasUniqueHomeEquityProgram : formData[FieldNames.hasUniqueHomeEquityProgram] === YesOrNo.YES,
      loanAmount                 : formData[FieldNames.loanAmount],
      loanPurpose                : formData[FieldNames.homeEquityLoanPurpose],
      mortgageBalance            : formData[FieldNames.mortgageBalance],
      propertyAppraisedValue     : formData[FieldNames.homeValue],
      propertyType               : mapRatesPropertyType(formData[FieldNames.propertyType]),
      propertyUsage              : mapRatesPropertyUsage(formData[FieldNames.propertyUsage]),
      propertyZip                : formData[FieldNames.propertyZip],
    };

    dispatch({ type: Type.AJAX_FETCH_HOME_EQUITY_RATES, request });

    return fetchHomeEquityRates(request).then((response) => {
      logger.debug('ajaxFetchHomeEquityRates response: ', response);
      dispatch(ajaxFetchHomeEquityRatesSuccess(response));
      return response;
    },
    (error) => {
      logger.error('ajaxFetchHomeEquityRates error: ', error);
      dispatch(ajaxFetchHomeEquityRatesFail(error));
      throw error;
    },
    );
  };
}

export function ajaxFetchPersonalLoanRates(formData) {
  return (dispatch: Dispatch) => {
    const request = {
      fico             : formData[FieldNames.creditScore],
      hasUniqueProgram : formData[FieldNames.hasUniquePersonalLoanProgram] === YesOrNo.YES,
      loanAmount       : formData[FieldNames.loanAmount],
      creditReportId   : formData[FieldNames.creditReportId],
      type             : ConsumerLoanRequestType.PERSONAL,
      zipCode          : formData[FieldNames.propertyZip],
    };

    dispatch({ type: Type.AJAX_FETCH_PERSONAL_LOAN_RATES, request });

    return fetchConsumerLoanRates(request).then(
      (response) => {
        logger.debug('fetchConsumerLoanRates response: ', response);
        response.forEach((program: any) => {
          mapComputedRateValues(program, false, formData[FieldNames.loanPurpose]);
        });
        dispatch(ajaxFetchPersonalLoanRatesSuccess(response));
        return response;
      },
      (error) => {
        dispatch(ajaxFetchPersonalLoanRatesFail(error));
        throw error;
      },
    );
  };
}

export function ajaxFetchAutoLoanRates(formData) {
  return (dispatch: Dispatch) => {
    const request = {
      creditReportId : formData[FieldNames.creditReportId],
      fico           : formData[FieldNames.creditScore],
      loanAmount     : formData[FieldNames.loanAmount],
      type           : ConsumerLoanRequestType.AUTO,
      vehiclePrice   : formData[FieldNames.purchasePrice],
      vehicleYear    : formData[FieldNames.vehicleYear],
      zipCode        : formData[FieldNames.propertyZip],
    };
    dispatch({ type: Type.AJAX_FETCH_AUTO_LOAN_RATES, request });

    return fetchConsumerLoanRates(request).then(
      (response) => {
        logger.debug('fetchConsumerLoanRates response: ', response);
        response.forEach((program: any) => {
          mapComputedRateValues(program, false, LoanPurpose.AUTO_LOAN);
        });
        dispatch(ajaxFetchAutoLoanRatesSuccess(response));
        return response;
      },
      (error) => {
        dispatch(ajaxFetchAutoLoanRatesFail(error));
        throw error;
      },
    );
  };
}

export function ajaxExpressRates(formData) {
  return (dispatch: Dispatch, getState) => {
    const payload = {
      fico       : formData[FieldNames.creditScore],
      loanAmount : formData[FieldNames.loanAmount],
      zip        : formData[FieldNames.propertyZip],
    };

    dispatch({ type: Type.AJAX_FETCH_EXPRESS_RATES, payload });
    return fetchRates(payload).then(
      (response) => {
        logger.debug('fetchExpressRates response: ', response);
        response.forEach((program: any) => {
          mapComputedRateValues(program, false, LoanPurpose[formData[FieldNames.loanPurpose]] as LoanPurpose );
        });
        dispatch(ajaxFetchRatesSuccess(response));
        return response;
      },
      (error) => {
        dispatch(ajaxFetchRatesFail(error));
        throw error;
      },
    );
  };
}

export function ajaxFetchConsumerLoanLimits(formData, type: ConsumerLoanRequestType) {
  return async (dispatch: Dispatch): Promise<ConsumerLoanLimitsResponse> => {
    const payload = {
      fico : formData[FieldNames.creditScore],
      type,
    };

    dispatch({ type: Type.AJAX_FETCH_CONSUMER_LOAN_LIMITS, payload });
    try {
      const response = await fetchConsumerLoanLimits(payload);
      logger.debug('fetchConsumerLoanLimits response: ', response);
      dispatch(ajaxFetchConsumerLoanLimitsSuccess(response));
      return response;
    } catch (error) {
      dispatch(ajaxFetchConsumerLoanLimitsFail(error));
      logger.error('Error when fetching consumer loan limits', error);
    }
  };
}

export function ajaxPostShortForm() {
  return (dispatch, getState) => {
    const state = getState();
    const formName = getFormName(state.router.location.pathname);
    const formData = getFormValues(formName)(state);
    const loanOfficer = getLoanOfficer(state);

    let payload: ShortFormRequestBody;

    // TODO: work towards removing the need for this conditional
    if (formName === FormName.REVERSE) {
      payload = {
        app           : mapShortFormApplication(formData),
        formName,
        loanOfficerId : loanOfficer.id,
        locale        : getLocale(),
        referralId    : formData[FieldNames.referralId],
        slug          : getSlug(),
      };
    } else {
      const rates = formName === FormName.HOMEEQUITY || formName === FormName.HOME_EQUITY_TURBO ?
        mapHomeEquityRatesToPayload(state.homeEquityRates) :
        mapRatesToPayload(state.rates);
      const avmReportId = selectAVMReportId(state);
      payload    = {
        app           : mapUserDataToPayload(formData, loanOfficer, avmReportId),
        formName,
        loanOfficerId : loanOfficer.id,
        locale        : getLocale(),
        rates,
        slug          : getSlug(),
      };
    }
    dispatch({ type: Type.AJAX_POST_USER_DATA, payload });
    return postShortForm(payload).then(
      (response) => {
        logger.debug('UserData response: ', response);
        dispatch(ajaxPostUserDataSuccess(response));
        return response;
      },
      (error) => {
        dispatch(ajaxPostUserDataFail(error));
      },
    );
  };
}

export function ajaxEmailRates(formData) {
  return (dispatch: Dispatch, getState) => {
    const state = getState();
    const config = state.config;
    const loanOfficer = getLoanOfficer(state);
    const payload = {
      userData              : {
        ...mapUserDataToPayload(formData, loanOfficer),
        slug: getSlug(),
      },
      rates                 : getState().rates,
      is_hide_fees_on_rates : config.featureToggles.isHideFeesOnRates,
    };
    dispatch({ type: Type.AJAX_EMAIL_RATES, payload });
    return postEmailRates(payload).then(
      (response) => {
        dispatch(ajaxEmailRatesSuccess(response));
      },
      (error) => {
        dispatch(ajaxEmailRatesFail(error));
      });
  };
}

export function ajaxPostChecklist(formData) {
  return (dispatch, getState) => {
    // Map the form values that are needed for the checklist
    const payload = mapChecklistRequest(formData, getState());
    dispatch({ type: Type.AJAX_POST_CHECKLIST, payload });
    return postChecklist(payload).then(
      (response) => {
        dispatch(ajaxPostChecklistSuccess(response));
        return response.checklist;
      },
      (error) => {
        dispatch(ajaxPostChecklistFail(error));
      },
    );
  };
}

/**
 * Remove street addresses from the payload if the feature toggle isHideStreetAddressFields is on
 * @param payload
 */
function removeStreetAddresses(payload) {
  return (dispatch, getState) => {
    const { isHideStreetAddressFields } = getState().config.featureToggles;
    if ( isHideStreetAddressFields ) {
      dispatch({ type: Type.REMOVE_STREET_ADDRESSES, payload });
      payload.LOAN_APPLICATION.BORROWER.forEach((borrower) => {
        borrower.RESIDENCES.forEach((residence) => {
          residence.street_address_1 = undefined;
          residence.street_address_2 = undefined;
        });
      });
      if (payload.LOAN_APPLICATION.SUBJECT_PROPERTY?.street_address_1) {
        payload.LOAN_APPLICATION.SUBJECT_PROPERTY.street_address_1 = undefined;
      }
      if (payload.LOAN_APPLICATION.SUBJECT_PROPERTY?.street_address_2) {
        payload.LOAN_APPLICATION.SUBJECT_PROPERTY.street_address_2 = undefined;
      }
    }
  };
}

export function handleLongFormSubmit(formData) {
  // Redux Thunk will inject dispatch here
  return (dispatch, getState) => {
    const state       = getState();
    const config      = state.config;
    const loanOfficer = getLoanOfficer(state);
    const formName = getFormName(state.router.location.pathname);
    const payload     = mapLongFormRequest(formData, formName, loanOfficer, config);

    // Remove street address fields if feature toggle is on
    dispatch(removeStreetAddresses(payload));

    // Dispatch the action we're calling in case we want the reducer to do anything
    dispatch({ type: Type.HANDLE_LONGFORM_SUBMIT, formData });
    dispatch({ type: Type.AJAX_POST_MORTGAGE_APP, payload });
    let auditMetadata;
    if (hasEitherSsn()) {
      auditMetadata = getAuditMetadata('Submit button');
    }
    return postLongFormApplication({ ...payload, auditMetadata }).then(
      (response) => {
        return Promise.all([
          dispatch(ajaxPostMortgageAppSuccess(response)),
          dispatch(setSubmittedData(formName, { ...payload, loanAppId: response.loanAppId })),
        ]).then(() => {
          dispatch(getNextSection());
        });
      },
      (error) => {
        dispatch(ajaxPostMortgageAppFail(error));
      },
    );
  };
}

export function handleLongFormConstructionSubmit(formData) {
  return (dispatch, getState) => {
    const state = getState();
    const config = state.config;
    const loanOfficer = getLoanOfficer(state);
    const payload = mapLongFormRequest(formData, FormName.CONSTRUCTION, loanOfficer, config);
    dispatch({ type: Type.HANDLE_LONGFORM_SUBMIT, formData });
    dispatch({ type: Type.AJAX_POST_MORTGAGE_APP, payload });
    let auditMetadata;
    if (hasEitherSsn()) {
      auditMetadata = getAuditMetadata('Submit button');
    }
    return postLongFormApplication({ ...payload, auditMetadata }).then(
      (response) => {
        return Promise.all([
          dispatch(ajaxPostMortgageAppSuccess(response)),
          dispatch(setSubmittedData(FormName.CONSTRUCTION, payload)),
        ]).then(() => {
          dispatch(getNextSection());
        });
      },
      (error) => {
        dispatch(ajaxPostMortgageAppFail(error));
      },
    );
  };
}

export function handleHomeEquityLongFormSubmitFirst(payload) {
  return async (dispatch, getState) => {
    const state = getState();
    const formName = getFormName(state.router.location.pathname);

    let isLocationValid = false;
    try {
      const locationResponse = await fetchHomeEquityAcceptedLocation({ propertyZip: payload[FieldNames.propertyZip] });
      isLocationValid = locationResponse.isValid;
    } catch (error) {
      const errorMessage = error.response.data?.error?.description ?? 'Invalid Zip';
      const errors = { [FieldNames.propertyZip] : errorMessage };
      throw new SubmissionError(errors);
    }

    const loanAmountLimitsRequest = mapHomeEquityLoanAmountLimitsRequest(payload);
    const { maxLoanAmount, maxLTV, minLoanAmount } = await fetchHomeEquityLoanAmountLimits(loanAmountLimitsRequest);

    // Set the max loan amount in payload because we set submitted data below
    payload[FieldNames.maxLoanAmount] = maxLoanAmount;
    payload[FieldNames.minLoanAmount] = minLoanAmount;
    payload[FieldNames.maxLtvPercent] = maxLTV;
    dispatch(change(formName, FieldNames.maxLoanAmount, maxLoanAmount));
    dispatch(change(formName, FieldNames.minLoanAmount, minLoanAmount));
    dispatch(change(formName, FieldNames.maxLtvPercent, maxLTV));

    dispatch({ type: Type.HANDLE_HOMEEQUITY_LONGFORM_SUBMIT_FIRST, payload });

    if (!isLocationValid) {
      return dispatch(goToSection(LF_HELOC_STATE_NOT_COVERED_STOP_GATE));
    } else {
      dispatch(setSubmittedData(FormName.HOMEEQUITYLONGFORM, payload));

      if (maxLoanAmount >= minLoanAmount) {
        return dispatch(getNextSection());
      } else {
        return dispatch(goToSection(LF_HELOC_MINIMUM_LOAN_STOP_GATE));
      }
    }
  };
}

export function handleHomeEquityLongFormSubmitSecond(formData) {
  return (dispatch, getState) => {
    const state = getState();
    const config = state.config;
    const loanOfficer = getLoanOfficer(state);
    const payload = mapLongFormRequest(formData, FormName.HOMEEQUITYLONGFORM, loanOfficer, config);
    dispatch({ type: Type.HANDLE_HOMEEQUITY_LONGFORM_SUBMIT_SECOND, formData });
    dispatch({ type: Type.AJAX_POST_MORTGAGE_APP, payload });

    if(payload.LOAN_APPLICATION.transfer_reason_code) {
      delete payload.LOAN_APPLICATION.transfer_reason_code;
    }

    let auditMetadata;
    if (hasEitherSsn()) {
      auditMetadata = getAuditMetadata('Submit button');
    }
    return postLongFormApplication({ ...payload, auditMetadata }).then(
      (response) => {
        dispatch(ajaxPostMortgageAppSuccess(response));
        dispatch(setSubmittedData(FormName.HOMEEQUITYLONGFORM, payload));
        dispatch(getNextSection());
      },
      (error) => {
        dispatch(ajaxPostMortgageAppFail(error));
      },
    );
  };
}

export function handleContactFormSubmit(formData) {
  const payload = mapContactUsPayload(formData);
  return async (dispatch, getState) => {
    dispatch({ type: Type.HANDLE_CONTACT_FORM_SUBMIT, formData });
    dispatch({ type: Type.AJAX_CONTACT_US, payload });

    try {
      const loanOfficer = getLoanOfficer(getState());
      const response = await postContactUsApplication(payload, loanOfficer);
      dispatch(ajaxContactUsSuccess(response));
    } catch(error) {
      dispatch(ajaxContactUsFail(error));
      throw new SubmissionError({ _error: error.errorMessage });
    }
  };
}

export function handleSavePartial(
  formData,
  source: PartialSource,
  code: GeneralReferralCode | '' = '',
) {
  return (dispatch, getState) => {
    const { isHideStreetAddressFields } = getState().config.featureToggles;

    // Remove street address fields if feature toggle is on
    if (isHideStreetAddressFields) {
      dispatch({ type: Type.REMOVE_STREET_ADDRESSES, formData });
      formData.livingPropertyStreet            = undefined;
      formData.livingPropertyStreet2           = undefined;
      formData.propertyStreet                  = undefined;
      formData.propertyStreet2                 = undefined;
      formData.coBorrowerLivingPropertyStreet  = undefined;
      formData.coBorrowerLivingPropertyStreet2 = undefined;
    }
    dispatch({ type: Type.HANDLE_SAVE_PARTIAL, formData, source, code });
    const isReferToLo = source !== 'SAVE';
    const hash        = getState().partial.hash;
    const uuid        = getState().partial.uuid;
    /* Refer to LO should always be a POST */
    if (!uuid || isReferToLo) {
      dispatch(ajaxPostPartialApp(formData));
    } else {
      dispatch(ajaxPutPartialApp(formData, hash, uuid));
    }
  };
}

export function ajaxPostPartialApp(formData) {
  return (dispatch, getState) => {
    const payload = mapPartialData(formData, getState());
    dispatch({ type: Type.AJAX_POST_PARTIAL_APP, payload });
    return postPartialApp(payload).then(
      (response) => {
        dispatch(ajaxPostPartialAppSuccess(response));
      },
      (error) => {
        dispatch(ajaxPostPartialAppFail(error));
      },
    );
  };
}

export function ajaxPutPartialApp(formData, hash, uuid) {
  return (dispatch, getState) => {
    const payload = mapPartialData(formData, getState());
    dispatch({ type: Type.AJAX_PUT_PARTIAL_APP, payload });
    return putPartialApp(payload, hash, uuid ).then(
      (response) => {
        dispatch(ajaxPutPartialAppSuccess(response));
      },
      (error) => {
        dispatch(ajaxPutPartialAppFail(error));
      });
  };
}

export function ajaxGetPartialApp(payload, redirect?: boolean) {
  return (dispatch, getState) => {
    dispatch({ type: Type.AJAX_GET_PARTIAL_APP, payload });
    return getPartialApp(payload).then(
      (response) => {
        const { path, sectionId, formValues } = response;
        const formName = getFormName(path);
        dispatch(ajaxGetPartialAppSuccess(response));
        if (response.source === PartialSource.REFERRED) {
          dispatch(push(`${path}#101`));
        } else {
          dispatch(initialize(formName, formValues, false));
          dispatch(push(`${path}#${sectionId}`));
        }
      },
      (error) => {
        dispatch(ajaxGetPartialAppFail(error));
        logger.warn('Could not get partial, redirecting to home');
        dispatch(push(`/`));
      },
    );
  };
}

export function handleDeclarationFlagsWhitelist(request, form) {
  return () => {
    switch (form) {
      case FormName.HOME_EQUITY_TURBO:
      case FormName.REFINANCE_TURBO:
      case FormName.PURCHASE_TURBO:
        request.borrowers.forEach((borrower) => {
          borrower.dec_borrowed_downpayment_flag = undefined;
          borrower.dec_family_relationship_flag  = undefined;
          borrower.dec_loan_obligation_flag      = undefined;
        });
        break;
      case FormName.AUTOPREQUAL:
        request.LOAN_APPLICATION.BORROWER.forEach((borrower) => {
          borrower.dec_home_owner_occupy_flag      = undefined;
          borrower.dec_home_owner_three_years_flag = undefined;
          borrower.dec_prior_property_usage_type   = undefined;
          borrower.dec_prior_property_title_type   = undefined;
          borrower.dec_family_relationship_flag    = undefined;
          borrower.dec_borrowed_downpayment_flag   = undefined;
          borrower.dec_mortgage_in_progress_flag   = undefined;
          borrower.dec_loan_obligation_flag        = undefined;
          borrower.dec_other_lien_flag             = undefined;
          borrower.dec_comaker_endorser_flag       = undefined;
          borrower.dec_outstanding_judgements_flag = undefined;
          borrower.dec_presently_delinquent_flag   = undefined;
          borrower.dec_party_to_lawsuit_flag       = undefined;
          borrower.dec_conveyed_title_flag         = undefined;
          borrower.dec_short_sale_flag             = undefined;
        });
    }
  };
}

export function ajaxPostPrequalLetter(formData) {
  return (dispatch, getState) => {
    // Map the form values that are needed for the prequal letter
    const payload = mapPrequalLetterRequest(formData, getState());
    dispatch({ type: Type.AJAX_POST_PREQUAL_LETTER, payload });
    return postPrequalLetter(payload).then(
      (response) => {
        dispatch(ajaxPostPrequalLetterSuccess(response));
        dispatch(setInitialFormValues({ formName: FormName.AUTOPREQUAL, formData }));
        // Scroll back to top
        window.scrollTo(0, 0);
      },
      (error) => {
        dispatch(ajaxPostPrequalLetterFail(error));
      },
    );
  };
}

export function handleAutoPrequalGetRates(payload, referToLoIf) {
  return (dispatch, getState) => {
    // Dispatch the action we're calling in case we want the reducer to do anything
    dispatch({ type: Type.HANDLE_AUTO_PREQUAL_GET_RATES, payload });
    return dispatch(ajaxGetPrequalRates(payload, true, referToLoIf)).then((response) => {
      // Reinitialize the form using the new values
      const initialValueDetails = {
        formName: FormName.AUTOPREQUAL,
        formData: getFormValues(FormName.AUTOPREQUAL)(getState()),
      };
      dispatch(setInitialFormValues(initialValueDetails));
    }).catch((error) => {
      throw error;
    });
  };
}

export function handleAutoPrequalRecalculateRates(payload) {
  return (dispatch, getState) => {
    dispatch({ type: Type.HANDLE_AUTO_PREQUAL_RECALCULATE_RATES, payload });
    // Get auto-prequal rates
    return dispatch(ajaxGetPrequalRates(payload, false)).then(() => {
      // Reinitialize the form using the new values
      const initialValueDetails = {
        formName: FormName.AUTOPREQUAL,
        formData: getFormValues(FormName.AUTOPREQUAL)(getState()),
      };
      dispatch(setInitialFormValues(initialValueDetails));
      // Scroll back to top
      window.scrollTo(0, 0);
    });
  };
}

export function handleAutoPrequalSubmit(formData) {
  return (dispatch, getState) => {
    const state       = getState();
    const config      = state.config;
    const loanOfficer = getLoanOfficer(state);
    const payload     = mapLongFormRequest(formData,FormName.AUTOPREQUAL, loanOfficer, config);

    // Remove street address fields if feature toggle is on
    dispatch(removeStreetAddresses(payload));

    // Dispatch the action we're calling in case we want the reducer to do anything
    dispatch(handleDeclarationFlagsWhitelist(payload, FormName.AUTOPREQUAL));
    dispatch({ type: Type.HANDLE_AUTO_PREQUAL_SUBMIT, formData });
    dispatch({ type: Type.AJAX_POST_MORTGAGE_APP, payload });
    return Promise.all([
      postLongFormApplication(payload),
      dispatch(ajaxPostPrequalLetter(formData)),
    ]).then(
      (response) => {
        dispatch(ajaxPostMortgageAppSuccess(response));
        dispatch(setSubmittedData(FormName.AUTOPREQUAL, payload));
        dispatch(getNextSection());
      },
      (error) => {
        dispatch(ajaxPostMortgageAppFail(error));
      });
  };
}

type GetPrequalLetterPayload = Omit<ResumeParams, 'type'> & { renew: boolean };

export function ajaxGetPrequalLetter(payload: GetPrequalLetterPayload) {
  return (dispatch, getState) => {
    dispatch({ type: Type.AJAX_GET_PREQUAL_LETTER, payload });
    return getPrequalLetter(payload).then(
      (response) => {
        const { formValues, prequalExpiration } = response;
        dispatch(ajaxGetPrequalLetterSuccess(response));
        /* If renewing or if returning with an expired prequal letter, redirect to prequal renew */
        if (payload.renew || isLetterExpired(prequalExpiration)) {
          /* Log if letter is expired */
          if (isLetterExpired(prequalExpiration)) {
            logger.debug('Prequal letter expired, redirecting to prequal renew', { prequalExpiration });
          }
          /* Initialize mapped form values from prequal letter response */
          dispatch(initialize(FormName.AUTOPREQUAL, mapPrequalLetterResponse(formValues), false));
          /* Redirect to the auto prequal renew form */
          dispatch(push(routes.autoPrequalRenew));
        } else {
          /* Initialize form values for prequal letter */
          dispatch(initialize(FormName.AUTOPREQUAL, formValues, false));
          /* Redirect to the auto prequal edit letter */
          dispatch(push(routes.autoPrequalEdit));
        }
      },
      (error) => {
        dispatch(ajaxGetPrequalLetterFail(error));
        logger.warn('Could not get prequal letter, redirecting to home');
        dispatch(push(routes.root));
      },
    );
  };
}

export function setLocale(payload: Language) {
  return (dispatch, getState) => {
    dispatch({ type: Type.SET_LOCALE, payload });
    i18next.changeLanguage(payload);
  };
}

/**
 * This method sets various conditionals from other parts of the state into
 * the form for easier evaluation. (i.e in places where we currently only
 * have the form data)
 *
 * @param {FormName} formName
 * @param {FieldNames} fieldName
 * @param {string} value
 */
export function handleSettingConditional(formName: FormName, fieldName: FieldNames, value: string) {
  return (dispatch) => {
    dispatch({ type: Type.SET_FORM_CONDITIONALS, formName, fieldName });
    logger.debug(`Setting ${fieldName} to:`, value);
    dispatch(change(formName, fieldName, value));
  };
}

/**
 * This method builds the new payload before submitting to the backend endpoint
 *
 * @param {FormName} formName
 * @param {FieldNames} fieldName
 * @param {string} value
 */
export const handleConsumerApplication = async (dispatch, loanOfficer: LoanOfficerDetail, payload, formName: FormName) => {
  const auditMetaData = getAuditMetadata('Next button');
  payload = { ...payload, slug: getSlug(), auditMetaData };
  try {
    dispatch({ type: Type.HANDLE_CONSUMER_APPLICATION_SUBMIT, payload });
    const consumerFormRequest = mapUniversalLoanRequest(payload, loanOfficer, formName);
    const response = await postUniversalLoanApplication(consumerFormRequest);
    dispatch(ajaxPostMortgageAppSuccess(response));
    dispatch(getNextSection(payload));
  } catch (error) {
    dispatch(ajaxPostMortgageAppFail(error));
    logger.error('Posting Consumer Form Submit error', error);
  }
};

/**
 * Handles the Credit Card form Submit from the ssn page
 *
 * @param {payload} payload
 * @param {FormName} formName
 */
export function submitCreditCardApplication(payload) {
  return (dispatch, getState) => {
    const loanOfficer = getLoanOfficer(getState());
    return handleConsumerApplication(dispatch, loanOfficer, payload, FormName.CREDITCARD);
  };
}

/**
 * This method handles the call to recalculate rates when the user
 * changes the loan amount.  It resets the submitted data, which is important
 * because the form uses that as it's initial values.
 *
 * @param payload
 * @returns
 */
export function handleTurboHomeEquityRecalculateRates(payload) {
  return async (dispatch) => {
    dispatch({ type: Type.HANDLE_RECALCULATE_RATES, payload });
    await Promise.resolve(dispatch(ajaxFetchHomeEquityRates(payload)));
    dispatch(setSubmittedData(FormName.HOME_EQUITY_TURBO, payload));
    window.scrollTo(0, 0); // Scroll back to top
  };
}

/**
 * Handles the all the Commercial Form Submits
 */
export function handleCommercialFormSubmit() {
  return async (dispatch) => {
    await dispatch(submitCommercialApplication());
    dispatch(getNextSection());
  };
}

/**
 * Builds the Commercial form request and submits to the Universal Loan Application end point
 */
export function submitCommercialApplication() {
  return async (dispatch, getState) => {
    const state = getState();
    const formName = getFormName(state.router.location.pathname);
    const formData = getFormValues(formName)(state);
    const loanOfficer = getLoanOfficer(state);

    const universalLoanRequest = mapUniversalLoanRequest(formData, loanOfficer, formName);
    return postUniversalLoanApplication(universalLoanRequest).then(
      (response) => {
        logger.debug('Post Commercial Form Response', response);
        return response;
      },
      (error) => {
        logger.error('Error: Post Commercial Form', error);
      },
    );
  };
}

/**
 * Handles the Financial Statement Submits
 */
export function handleFinancialStatementFormSubmit() {
  return async (dispatch) => {
    await dispatch(submitFinancialStatementApplication());
    dispatch(getNextSection());
  };
}

export function submitFinancialStatementApplication() {
  return async (dispatch, getState) => {
    const state = getState();
    const formName = getFormName(state.router.location.pathname);
    const formData = getFormValues(formName)(state);
    const loanOfficer = getLoanOfficer(state);

    try {
      let loanApplication = {
        assets                     : [],
        borrower                   : [],
        birthDate                  : '',
        coborrowerOnLoanFlag       : 0 as Binary,
        employment                 : [],
        propertyZip                : '',
        realEstateLiabilities      : [],
        titleFirstName: '',
        titleLastName: '',
        titleMiddleName: '',
        commercialLoan: undefined,
        financialStatement: mapFinancialStatement(formData),
      };
      let source = UniversalLoanSource.FINANCIAL_STATEMENT;
      if(formData[FieldNames.existingLoanNumber]?.length > 0) {
        source = UniversalLoanSource.COMMERCIAL_RENEW;
        loanApplication.commercialLoan = handleCommercialMapping(formData, formName);
      }
      const { statement } = await postUniversalLoanApplication(
        {
          auditMetaData: JSON.stringify(getAuditMetadata('Submit')),
          creditReportId : formData[FieldNames.creditReportId],
          source,
          loanApplication,
          loanOfficerId   : loanOfficer.id,
        });

      if(statement) {
        formData['statement'] = statement;
      }
    }
    catch (error) {
      logger.error(`Submit Financial Statement Error: ${ error }`);
    }
  };
}

export function handleCommercialFormTypeSelect(payload) {
  return (dispatch, getState) => {
    let toForm = payload[FieldNames.loanType];
    dispatch({ type: Type.HANDLE_COMMERCIAL_LOAN_TYPE_SELECT, payload });
    const initialValueAndCommercial = { ...returnInitialValues(toForm), ...getFormValues(FormName.COMMERCIAL)(getState()) };

    const initialValueDetails   = {
      formName: toForm,
      formData: Object.assign({}, {}, initialValueAndCommercial),
    };
    dispatch(setInitialFormValues(initialValueDetails));
    return dispatch(push(getRouteByFormName(toForm) + `#${101}`));
  };
}

export const handleCommercialBackButton = (payload): boolean  => {
  return payload[FieldNames.loanType] === undefined;
};
