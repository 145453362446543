import React from 'react';
import { connect } from 'react-redux';
import { ImageSection } from 'app/components/ImageSection';
import { Button, Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { contactPhoneSelector } from 'app/reducers/app-config';

const NoHomeEquityRatesComponent = ({ ...props }: any) => {
  const { clientName, contactPhone, stopGateHeloc, t } = props;
  return (
    <ImageSection image={stopGateHeloc}>
      <Typography variant='h6' gutterBottom={true} classes={{ h6: 'contentTitle' }}>
        {t('stopGate.noHomeEquityRates.title', { defaultValue: 'Let us assist you' })}
      </Typography>
      <Typography variant='body2' gutterBottom={true}>
        {
          t('stopGate.noHomeEquityRates.paragraph1', 
            { 
              clientName,
              defaultValue: 'We are going to refer you to a loan officer who will be able to discuss the rest of the process and go over your options.',
            },
          )
        }
      </Typography>
      <Button
        classes={{ root: 'stackedButton', fullWidth: 'tabletSizeLarge' }}
        color='primary'
        fullWidth
        href={`tel:+1${contactPhone}`}
        size='large'
        variant='contained'
      >
        {t('stopGate.referToLoanOfficer.button.call', {
          defaultValue: 'Call us at {{ contactPhone, phone }}',
          contactPhone,
        })}
      </Button>
    </ImageSection>
  );
};

const mapStateToProps = (state) => ({
  stopGateHeloc : state.config.stopGateHelocImage,
  clientName    : state.config.clientDisplayName,
  contactPhone  : contactPhoneSelector(state),
});

export const NoHomeEquityRates = connect(mapStateToProps)(withTranslation()(NoHomeEquityRatesComponent));
