import React from 'react';
import { Field } from 'redux-form';
import { mapAutoCompleteName } from 'app/util/autocomplete';
import {
  noopValidate,
  required,
  isValidDate,
  isDateInPast,
  VALIDATORS,
} from 'app/util/validators';
import { renderDateBase } from 'app/components/FormFields/_renderDateBase';
import moment from 'moment';

export const SinglePastDate = ({ ...props }) => {
  const { name, label, isOptional, inputClasses, labelClasses } = props;
  /* If the question isOptional, replace the required validator */
  const isRequired = isOptional ? noopValidate : required;

  /* The validator property comes from the question object */
  const { type, param } = props.validator || { type: '', param: '' };
  const conditionalValidator = VALIDATORS[type]
    ? VALIDATORS[type](param)
    : noopValidate;

  return (
    <Field
      autoComplete    = {mapAutoCompleteName(name)}
      component       = {renderDateBase}
      format          = {(date) => date}
      normalize       = {(date: any) => moment(date).isValid() ? moment(date).toISOString() : date }
      inputClasses    = {inputClasses}
      label           = {label}
      labelClasses    = {labelClasses}
      name            = {name}
      validate        = {[isRequired, isValidDate, isDateInPast, conditionalValidator]}
      datePickerProps = {{
        disableFuture: true,
      }}
    />
  );
};
