import { FieldNames } from 'app/models/fields/names';
import { Section } from 'app/models/types';
import { HOME_EQUITY_RATES_HOUSING, HOME_EQUITY_RATES_CREDIT_SCORE, HOME_EQUITY_RATES_NO_RATES_FOUND } from './constants';
import { homeEquityRatesQuestionsByName } from 'app/models/questions/home-equity-rates';
import { handleHomeEquityGetRates } from 'app/actions/form/turbo/actions';
import { NoHomeEquityRates } from 'app/components/StopGates/NoHomeEquityRates';

export const getHomeEquityRatesSectionById = (id: number): Section => {
  return HOME_EQUITY_SECTIONS.find((section) => section.id === id) as Section;
};

const HOME_EQUITY_SECTIONS: Section[] = [
  {
    id       : HOME_EQUITY_RATES_HOUSING,
    title    : 'Housing Information',
    questions: homeEquityRatesQuestionsByName([
      FieldNames.homeValue,
      FieldNames.mortgageBalance,
      FieldNames.propertyUsage,
      FieldNames.loanAmount,
      FieldNames.propertyState,
      FieldNames.propertyZip,
    ]),
    hideBackButton : true,
    hideProgressBar: true,
  },
  {
    id       : HOME_EQUITY_RATES_CREDIT_SCORE,
    questions: homeEquityRatesQuestionsByName([
      FieldNames.creditScore,
    ]),
    hideProgressBar: true,
    submitAction: handleHomeEquityGetRates,
  },
  {
    id             : HOME_EQUITY_RATES_NO_RATES_FOUND,
    ifNoQuestions  : NoHomeEquityRates,
    hideProgressBar: true,
  },
];
